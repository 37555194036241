import React from 'react'
import { useParams } from 'react-router-dom'
import Alert from '../../Components/Alert'
import ContentHeader from '../../Components/ContentHeader'
import Layout from '../../Components/Layout'
import Loading from '../../Components/Loading'
import useClubsService from '../../Services/ClubsService'
import ShowDetail from './components/ShowDetail'

interface RouterParams {
  clubId: string
}

const ClubDetail: React.FC = () => {
  const { clubId } = useParams<RouterParams>()

  const { data, loading, error } = useClubsService({
    includeNonPublic: true,
    id: clubId,
  })
  const club = data?.clubs[0]
  return (
    <Layout>
      <ContentHeader title='Club detail' />
      <section className='content'>
        {loading ? (
          <Loading />
        ) : (
          <>
            {error || !club ? (
              <Alert>An error occurred while querying the data.</Alert>
            ) : (
              <ShowDetail club={club} />
            )}
          </>
        )}
      </section>
    </Layout>
  )
}

export default ClubDetail
