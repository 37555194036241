import React, { useState } from 'react'
import { DateTime } from 'luxon'
import { Model } from '../../../../Interfaces/Model'
import Select from '../../../../Components/Select'
import StatusBadge from '../../../../Components/StatusBadge'
import Button from '../../../../Components/Button'
import Dropdown from '../../../../Components/Dropdown'
import Alert from '../../../../Components/Alert'
import useUpdateModelVisibility from '../../../../Services/ModelVisibilityChange'
import getGraphQLErrors from '../../../../Utils/GetGraphQLErrors'

interface Props {
  models: Model[]
}

const ModelsList: React.FC<Props> = ({ models }) => {
  const [updateModelVisibility] = useUpdateModelVisibility()
  const [generalErrors, setGeneralErrors] = useState<string>()

  const handleChangeVisibility = async (
    modelId: string,
    publicStatus: boolean
  ): Promise<void> => {
    setGeneralErrors('')
    try {
      await updateModelVisibility({
        variables: {
          modelId,
          public: publicStatus,
        },
        update: async cache => {
          await cache.reset()
        },
      })
    } catch (err) {
      const defaultError = 'Failed. Try again.'
      const graphqlErrors = getGraphQLErrors(err, defaultError)
      const errorsArray = Object.values(graphqlErrors)
      setGeneralErrors(errorsArray[0]?.[0] || defaultError)
    }
  }

  return (
    <>
      {generalErrors && <Alert>{generalErrors}</Alert>}
      <table className='table table-striped table-hover projects'>
        <thead>
          <tr>
            <th style={{ width: '5%' }}>#</th>
            <th>Name </th>
            <th>Modeler</th>
            <th>Visibility</th>
            <th>Sensitive</th>
            <th className='text-center'>Status</th>
            <th aria-label='Actions' />
          </tr>
        </thead>
        <tbody>
          {models.length > 0 ? (
            models.map(model => (
              <tr key={model.id}>
                <td>
                  <img
                    alt={model.name}
                    className='table-avatar'
                    src={model.featuredPhoto.photo}
                  />
                </td>
                <td>
                  <a>{model.name}</a>
                  <br />
                  <small>
                    Created{' '}
                    {DateTime.fromISO(model.createdAt)
                      .setLocale('en')
                      .toLocaleString(DateTime.DATETIME_MED)}
                  </small>
                </td>
                <td>
                  <a>{model.modeler?.name || '-'}</a>
                </td>
                <td className='project_progress'>
                  <Select
                    value={model.public ? 'true' : 'false'}
                    onChange={e =>
                      handleChangeVisibility(
                        model.id,
                        JSON.parse(e.target.value)
                      )
                    }
                  >
                    <Select.Option value='true'>Visible</Select.Option>
                    <Select.Option value='false'>Hidden</Select.Option>
                  </Select>
                </td>
                <td>{model.sensitiveContent ? 'yes' : 'no'}</td>
                <td className='project-state'>
                  <StatusBadge status={model.status} />
                </td>
                <td className='text-right'>
                  <Dropdown>
                    <Button
                      color='info'
                      className='dropdown-toggle'
                      data-toggle='dropdown'
                    >
                      Actions
                    </Button>
                    <Dropdown.Menu isMenuEnd>
                      {model.modelsVersionsSearchable.items.length > 0 && (
                        <Dropdown.Item to={`model-version-pending/${model.id}`}>
                          Review pending version
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={6} className='text-center'>
                No models found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  )
}

export default ModelsList
