export enum Currency {
  BRL = 'BRL',
}

const formatCurrency = (
  value: number,
  currency: Currency = Currency.BRL
): string => {
  let symbol: string
  let locale: string
  switch (currency) {
    case Currency.BRL:
      symbol = 'R$'
      locale = 'pt-BR'
      break
    default:
      symbol = ''
      locale = ''
      break
  }
  return `${symbol}${value.toLocaleString(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`
}

export default formatCurrency
