import { ApolloProvider } from '@apollo/client'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorDisplay from './Components/ErrorDisplay'
import { AuthProvider } from './Contexts/AuthContext'
import Routes from './routes'
import client from './Services/ApolloClient'
import './App.scss'
import { SettingsProvider } from './Contexts/SettingsContext'
import 'react-day-picker/lib/style.css'

const App: React.FC = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorDisplay}>
      <ApolloProvider client={client}>
        <AuthProvider>
          <SettingsProvider>
            <Routes />
          </SettingsProvider>
        </AuthProvider>
      </ApolloProvider>
    </ErrorBoundary>
  )
}

export default App
