import { MutationTuple, gql, useMutation } from '@apollo/client'

const READ_NOTIF = gql`
  mutation readNotification($notificationId: ID!) {
    readNotification(notificationId: $notificationId)
  }
`

interface NotificationsVars {
  notificationId?: string
}

const useNotificationsRead = (): MutationTuple<boolean, NotificationsVars> => {
  return useMutation(READ_NOTIF)
}

export default useNotificationsRead
