import React from 'react'
import Body, { BodyComponent } from './components/Body'
import Footer, { FooterComponent } from './components/Footer'
import Header, { HeaderComponent } from './components/Header'

export interface ModalBaseProps {
  show?: boolean
  onClose?: () => void
  isStaticBackdrop?: boolean
}

type ModalBaseComponent = React.FC<ModalBaseProps> & {
  Body: BodyComponent
  Header: HeaderComponent
  Footer: FooterComponent
}

const ModalBase: ModalBaseComponent = ({
  show = false,
  onClose,
  isStaticBackdrop = false,
  children,
}) => {
  const onClickBackdrop = () => {
    if (!isStaticBackdrop && onClose) {
      onClose()
    }
  }
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <div
        className={`modal fade ${show ? 'show' : ''}`}
        tabIndex={-1}
        style={{ display: show ? 'block' : 'none' }}
        onClick={onClickBackdrop}
        role='dialog'
      >
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div className='modal-dialog' onClick={e => e.stopPropagation()}>
          <div className='modal-content'>{children}</div>
        </div>
      </div>
      {show && <div className='modal-backdrop fade show' />}
    </>
  )
}
ModalBase.Body = Body
ModalBase.Header = Header
ModalBase.Footer = Footer

export default ModalBase
