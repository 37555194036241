import { gql, QueryResult, useQuery } from '@apollo/client'
import { Model, ModelsSearchable } from '../Interfaces/Model'
import { ModelVersion } from '../Interfaces/ModelVersion'
import { SearchableFilter } from '../Interfaces/SearchableFilter'

const GET_MODELS = gql`
  query modelsSearchable(
    $filter: SearchableFilter
    $hasPendingVersion: Boolean
    $filterModelsVersions: SearchableFilter
  ) {
    modelsSearchable(filter: $filter, hasPendingVersion: $hasPendingVersion) {
      items {
        id
        name
        slug
        category {
          id
          name {
            name
            language
          }
        }
        scale
        sensitiveContent
        featuredPhoto {
          id
          photo
        }
        photos {
          id
          photo
        }
        finalFile {
          id
          originalName
        }
        modeler {
          name
        }
        status
        public
        createdAt
        description
        tags
        skipRaisingFunds
        amount
        totalQuotas
        video {
          id
          embedUrl
          thumbUrl
        }
        modelsVersionsSearchable(filter: $filterModelsVersions) {
          items {
            id
            status
            versionCount
            info {
              name
              slug
              category {
                id
                name {
                  name
                  language
                }
              }
              scale
              sensitiveContent
              description
              featuredPhoto {
                id
                photo
              }
              photos {
                id
                photo
              }
              finalFile {
                id
                originalName
              }
              tags
              amount
              totalQuotas
              video {
                id
                embedUrl
                thumbUrl
              }
            }
          }
        }
      }
      totalCount
    }
  }
`

interface GetModelsData {
  modelsSearchable: ModelsSearchable
}

export interface GetModelsVars {
  filter?: SearchableFilter<Model>
  hasPendingVersion?: boolean
  filterModelsVersions?: SearchableFilter<ModelVersion>
}

const useModelsSearchable = (
  variables?: GetModelsVars
): QueryResult<GetModelsData> => {
  return useQuery<GetModelsData, GetModelsVars>(GET_MODELS, {
    variables,
  })
}

export default useModelsSearchable
