import React from 'react'

export interface Props {
  checked?: boolean
  className?: string
  id?: string
  isInvalid?: boolean
  isWarning?: boolean
  multiple?: boolean
  name?: string
  onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
  placeholder?: string
  type?: string
  value?: number | string
  disabled?: boolean
}

const Input: React.FC<Props> = ({
  checked,
  className,
  id,
  isInvalid = false,
  isWarning = false,
  multiple,
  name,
  onBlur,
  onChange,
  placeholder,
  type = 'text',
  value,
  disabled = false,
}) => {
  const isInvalidClass = isInvalid ? 'is-invalid' : ''
  const isWarningClass = isWarning ? 'is-warning' : ''
  const inputProps = {
    className: ['form-control', isInvalidClass, isWarningClass, className].join(
      ' '
    ),
    placeholder,
    value,
    onBlur,
    onChange,
    name,
    id,
    disabled,
  }
  if (multiple) {
    return <textarea {...inputProps} />
  }
  return <input {...inputProps} type={type} checked={checked} />
}

export default Input
