import { Category } from './Category'
import { ModelFile } from './ModelFile'
import { Photo } from './Photo'
import { SearchableResult } from './SearchableResult'
import { VideoInfo } from './VideoInfo'

export interface ModelVersion {
  id: string
  status: string
  versionCount: number
  info: ModelVersionInfo
}

export interface ModelVersionInfo {
  name: string
  slug: string
  category: Category
  scale: string
  sensitiveContent: boolean
  description?: string
  featuredPhoto?: Photo
  photos?: Photo[]
  tags?: string[]
  amount?: number
  totalQuotas?: number
  finalFile?: ModelFile[]
  video?: VideoInfo
}

export type ModelsVersionsSearchable = SearchableResult<ModelVersion>

export enum ModelVersionAnswers {
  'ACCEPT' = 'ACCEPT',
  'REFUSE' = 'REFUSE',
}
