import { TransactionTypes } from '../Interfaces/Transaction'

const transactionTypeReadable = (type: TransactionTypes): string => {
  return {
    CREDITS_PURCHASE: 'Credits Purchase',
    PURCHASE: 'Purchase',
    CHARGEBACK: 'Chargeback',
    WITHDRAW: 'Withdraw',
    WITHDRAW_FEE: 'Withdraw Fee',
    COLLECTION: 'Modeler Collection',
    COLLECTION_CHARGEBACK: 'Modeler Collection Chargeback',
    EXPENSE: 'Expense',
    REVENUE: 'Revenue',
  }[type]
}

export default transactionTypeReadable
