import { gql, QueryResult, useQuery } from '@apollo/client'
import { Setting } from '../Interfaces/Setting'

const GET_SETTINGS = gql`
  query getSettings {
    getSettings {
      administrativeFee
      processingFee
      gatewayFixedFee
    }
  }
`

interface GetSettingsData {
  getSettings: Setting
}

const useGetSettings = (): QueryResult<GetSettingsData> => {
  return useQuery<GetSettingsData>(GET_SETTINGS)
}

export default useGetSettings
