import { gql, QueryResult, useQuery } from '@apollo/client'
import { Notification } from '../Interfaces/Notification'

const GET_NOTIF = gql`
  query notifications($skip: Int!, $limit: Int!) {
    notifications(adminNotifications: true, skip: $skip, limit: $limit) {
      notifications {
        id
        isAdmin
        title
        readedAt
        createdAt
      }
      hasUnread
      totalCount
    }
  }
`

interface GetNotificationsResponse {
  notifications: Notification[]
  hasUnread: boolean
  totalCount: number
}

interface GetNotificationData {
  notifications: GetNotificationsResponse
}

export interface GetNotificationsVars {
  skip: number
  limit: number
}

const useNotificationsService = (
  variables: GetNotificationsVars
): QueryResult<GetNotificationData> => {
  return useQuery<GetNotificationData, GetNotificationsVars>(GET_NOTIF, {
    variables,
  })
}

export default useNotificationsService
