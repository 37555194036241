import React, { useState } from 'react'
import { useFormik } from 'formik'
import Alert from '../../Components/Alert'
import ContentHeader from '../../Components/ContentHeader'
import Layout from '../../Components/Layout'
import Card from '../../Components/Card'
import Button from '../../Components/Button'
import FormField from '../../Components/FormField'
import Select from '../../Components/Select'
import Loading from '../../Components/Loading'
import useCreateTransaction from '../../Services/CreateTransation'
import getGraphQLErrors from '../../Utils/GetGraphQLErrors'
import {
  TransactionInput,
  TransactionStatus,
  TransactionTypes,
} from '../../Interfaces/Transaction'

const InsertTransaction: React.FC = () => {
  const [createTransaction, { loading }] = useCreateTransaction()
  const [message, setMessage] = useState<string>()
  const [messageSuccess, setMessageSuccess] = useState<boolean>()

  const initialValues = {
    transaction: {
      status: TransactionStatus.COMPLETED,
      type: TransactionTypes.EXPENSE,
      value: '',
      description: '',
      paymentDate: '',
    },
  }

  const insertData = async (transaction: TransactionInput): Promise<void> => {
    try {
      setMessage('')
      await createTransaction({
        variables: {
          transaction,
        },
      })
      setMessageSuccess(true)
      setMessage('Data entered successfully')
    } catch (error) {
      const defaultError = 'Failed. Try again.'
      const graphqlErrors = getGraphQLErrors(error, defaultError)
      const errorsArray = Object.values(graphqlErrors)
      setMessageSuccess(false)
      setMessage(errorsArray[0]?.[0] || defaultError)
    }
  }
  const formik = useFormik({
    initialValues,
    onSubmit: async values => {
      const newValues = {
        status: values.transaction.status,
        type: values.transaction.type,
        value: parseFloat(values.transaction.value),
        description: values.transaction.description,
        paymentDate: `${values.transaction.paymentDate}T12:00:00.000+00:00`,
      }
      insertData(newValues)
    },
  })

  const getFieldProps = (key: string) => ({
    ...formik.getFieldProps(`transaction.${key}`),
    type: 'text',
    // isInvalid: !!fieldErrors(key),
    // isValid: formik.touched.club?.[key] && !fieldErrors(key),
  })
  return (
    <Layout>
      <ContentHeader title='Insert transaction' />
      <section className='content'>
        <form noValidate onSubmit={formik.handleSubmit}>
          <section className='content'>
            {loading ? (
              <Loading />
            ) : (
              <>
                {message && (
                  <Alert color={messageSuccess ? 'success' : 'danger'}>
                    {message}
                  </Alert>
                )}
                <Card>
                  <Card.Header title='Basic data' />
                  <Card.Body>
                    <div className='row'>
                      <FormField
                        label='Status'
                        className='col'
                        select
                        {...getFieldProps('status')}
                      >
                        <Select.Option value='COMPLETED'>
                          COMPLETED
                        </Select.Option>
                        <Select.Option value='CREATED'>CREATED</Select.Option>
                        <Select.Option value='CANCELED'>CANCELED</Select.Option>
                      </FormField>
                      <FormField
                        label='Type'
                        className='col'
                        select
                        {...getFieldProps('type')}
                      >
                        <Select.Option value='EXPENSE'>EXPENSE</Select.Option>
                        <Select.Option value='REVENUE'>REVENUE</Select.Option>
                      </FormField>
                    </div>

                    <div className='row'>
                      <FormField
                        label='Description'
                        className='col'
                        {...getFieldProps('description')}
                      />
                      <FormField
                        label='Value'
                        className='col'
                        {...getFieldProps('value')}
                        type='text'
                      />
                      <FormField
                        label='Data'
                        className='col'
                        {...getFieldProps('paymentDate')}
                        type='date'
                      />
                    </div>
                  </Card.Body>
                </Card>
              </>
            )}
          </section>
          <Button type='submit' color='primary' className='mr-2'>
            Insert and save
          </Button>
          <a className='btn btn-secondary active' href='/'>
            Cancel
          </a>
        </form>
      </section>
    </Layout>
  )
}

export default InsertTransaction
