import { ApolloError, gql, useQuery } from '@apollo/client'
import { IWhereObject } from '../Interfaces/SearchableFilter'
import { TransactionsResults } from '../Interfaces/Transaction'

const GET_BALANCES = gql`
  query balances(
    $wherePreviousEarnings: JSONObject!
    $wherePreviousExpenses: JSONObject!
    $whereEarnings: JSONObject!
    $wherePredictedEarnings: JSONObject!
    $whereExpenses: JSONObject!
    $wherePredictedExpenses: JSONObject!
    $wherePredictedPreviousEarnings: JSONObject!
    $wherePredictedPreviousExpenses: JSONObject!
  ) {
    previousEarnings: transactionsSearchable(
      filter: { where: $wherePreviousEarnings }
    ) {
      ...aggregationSum
    }
    previousExpenses: transactionsSearchable(
      filter: { where: $wherePreviousExpenses }
    ) {
      ...aggregationSum
    }
    earnings: transactionsSearchable(filter: { where: $whereEarnings }) {
      ...aggregationSum
    }
    predictedEarnings: transactionsSearchable(
      filter: { where: $wherePredictedEarnings }
    ) {
      ...aggregationSum
    }
    expenses: transactionsSearchable(filter: { where: $whereExpenses }) {
      ...aggregationSum
    }
    predictedExpenses: transactionsSearchable(
      filter: { where: $wherePredictedExpenses }
    ) {
      ...aggregationSum
    }
    predictedPreviousEarnings: transactionsSearchable(
      filter: { where: $wherePredictedPreviousEarnings }
    ) {
      ...aggregationSum
    }
    predictedPreviousExpenses: transactionsSearchable(
      filter: { where: $wherePredictedPreviousExpenses }
    ) {
      ...aggregationSum
    }
  }
  fragment aggregationSum on TransactionsSearchable {
    aggregate {
      sum {
        netValue
      }
    }
  }
`

interface TransactionAggregationSum {
  aggregate: {
    sum: {
      netValue: number
    }
  }
}

interface GetBalancesData {
  previousEarnings: TransactionAggregationSum
  previousExpenses: TransactionAggregationSum
  earnings: TransactionAggregationSum
  predictedEarnings: TransactionAggregationSum
  expenses: TransactionAggregationSum
  predictedExpenses: TransactionAggregationSum
  predictedPreviousEarnings: TransactionAggregationSum
  predictedPreviousExpenses: TransactionAggregationSum
}

export interface GetBalancesVars {
  wherePreviousEarnings: IWhereObject
  wherePreviousExpenses: IWhereObject
  whereEarnings: IWhereObject
  wherePredictedEarnings: IWhereObject
  whereExpenses: IWhereObject
  wherePredictedExpenses: IWhereObject
  wherePredictedPreviousEarnings: IWhereObject
  wherePredictedPreviousExpenses: IWhereObject
}

interface TransactionsResultsResult {
  loading: boolean
  data?: TransactionsResults
  error?: ApolloError
}

const completedFilter = { status: 'COMPLETED' }
const notCanceledFilter = { status: { ne: 'CANCELED' } }
const earningFilter = { type: { in: ['CREDITS_PURCHASE', 'REVENUE'] } }
const expenseFilter = { type: { in: ['WITHDRAW', 'EXPENSE'] } }
const getVariables = (startDate: string, endDate: string): GetBalancesVars => {
  const beforeStartFilter = { updatedAt: { lt: { toDate: startDate } } }
  const betweenFilter = {
    updatedAt: { gte: { toDate: startDate }, lte: { toDate: endDate } },
  }
  const beforeEndFilter = { updatedAt: { lte: { toDate: endDate } } }
  return {
    wherePreviousEarnings: {
      ...beforeStartFilter,
      ...completedFilter,
      ...earningFilter,
    },
    wherePreviousExpenses: {
      ...beforeStartFilter,
      ...completedFilter,
      ...expenseFilter,
    },
    whereEarnings: {
      ...betweenFilter,
      ...completedFilter,
      ...earningFilter,
    },
    wherePredictedEarnings: {
      ...betweenFilter,
      ...notCanceledFilter,
      ...earningFilter,
    },
    whereExpenses: {
      ...betweenFilter,
      ...completedFilter,
      ...expenseFilter,
    },
    wherePredictedExpenses: {
      ...betweenFilter,
      ...notCanceledFilter,
      ...expenseFilter,
    },
    wherePredictedPreviousEarnings: {
      ...beforeEndFilter,
      ...notCanceledFilter,
      ...earningFilter,
    },
    wherePredictedPreviousExpenses: {
      ...beforeEndFilter,
      ...notCanceledFilter,
      ...expenseFilter,
    },
  }
}

const getData = (data: GetBalancesData): TransactionsResults => {
  const previousEarnings = data.previousEarnings.aggregate.sum.netValue
  const previousExpenses = data.previousExpenses.aggregate.sum.netValue
  const previousBalance = previousEarnings - previousExpenses
  const earnings = data.earnings.aggregate.sum.netValue
  const predictedEarnings = data.predictedEarnings.aggregate.sum.netValue
  const expenses = data.expenses.aggregate.sum.netValue
  const predictedExpenses = data.predictedExpenses.aggregate.sum.netValue
  const balance = earnings - expenses + previousBalance
  const predictedPreviousEarnings =
    data.predictedPreviousEarnings.aggregate.sum.netValue
  const predictedPreviousExpenses =
    data.predictedPreviousExpenses.aggregate.sum.netValue
  const predictedBalance = predictedPreviousEarnings - predictedPreviousExpenses
  return {
    previousBalance,
    earnings,
    predictedEarnings,
    expenses,
    predictedExpenses,
    balance,
    predictedBalance,
  }
}

const useTransactionsResults = (
  startDate: string,
  endDate: string
): TransactionsResultsResult => {
  const { data, loading, error } = useQuery<GetBalancesData, GetBalancesVars>(
    GET_BALANCES,
    {
      variables: getVariables(startDate, endDate),
    }
  )
  return {
    loading,
    data: data && !error ? getData(data) : undefined,
    error,
  }
}

export default useTransactionsResults
