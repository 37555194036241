import React from 'react'

interface Props {
  className?: string
  color?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'info'
    | 'danger'
    | 'warning'
    | 'default'
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  pill?: boolean
}

const Badge: React.FC<Props> = ({
  children,
  className,
  color = 'default',
  onClick,
  pill = false,
}) => {
  const props = {
    className: `badge badge-${color}${pill ? ' badge-pill' : ''}${
      className ? ` ${className}` : ''
    }`,
  }
  if (onClick == null) {
    return <span {...props}>{children}</span>
  }
  return (
    <a {...props} href='#' onClick={onClick}>
      {children}
    </a>
  )
}

export default Badge
