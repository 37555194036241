const refreshSession = async (): Promise<Response> => {
  return fetch(`${process.env.REACT_APP_API_URL}/refresh_token`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      remember: 'true',
      loginType: 'ADMIN',
    }),
  })
}

export default refreshSession
