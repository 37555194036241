import React from 'react'
import Gallery, { GalleryItem } from '../../../../Components/Gallery'
import { Photo } from '../../../../Interfaces/Photo'

interface Props {
  label: string
  className?: string
  newItems: Photo[]
  oldItems: Photo[]
}

const GalleryCompare: React.FC<Props> = ({
  label,
  className,
  newItems,
  oldItems,
}) => {
  let isWarning = false
  const newItemsCompared = newItems.map<GalleryItem>(item => {
    const isOld = oldItems.find(oldItem => oldItem.id === item.id)
    if (isOld == null) isWarning = true
    return {
      id: item.id,
      photo: item.photo,
      className: isOld == null ? 'border border-success border-4' : undefined,
    }
  })
  const oldItemsCompared = oldItems.map<GalleryItem>(item => {
    const isNew = newItems.find(newItem => newItem.id === item.id)
    if (isNew == null) isWarning = true
    return {
      id: item.id,
      photo: item.photo,
      className: isNew == null ? 'border border-danger border-4' : undefined,
    }
  })
  return (
    <div
      className={`${className} ${
        isWarning ? 'border border-warning rounded' : ''
      }`}
    >
      <p className='mb-2'>
        <strong>{label}</strong>
      </p>
      <p className='m-0'>How will it be</p>
      {newItemsCompared.length > 0 ? (
        <Gallery photos={newItemsCompared} itemWidth={130} />
      ) : (
        <p style={{ fontSize: 13 }}>Without photos.</p>
      )}
      <p className='m-0'>How was</p>
      {oldItemsCompared.length > 0 ? (
        <Gallery photos={oldItemsCompared} itemWidth={130} />
      ) : (
        <p style={{ fontSize: 13 }}>Without photos.</p>
      )}
    </div>
  )
}

export default GalleryCompare
