import React from 'react'
import Modal from '../../../../Components/Modal'
import { ModelVersionAnswers } from '../../../../Interfaces/ModelVersion'

interface Props {
  show: boolean
  onClose: () => void
  onConfirm: () => void
  loadingConfirm?: boolean
  answer?: ModelVersionAnswers
}

const ModalConfirm: React.FC<Props> = ({
  show,
  onClose,
  onConfirm,
  loadingConfirm = false,
  answer,
}) => {
  return (
    <Modal
      show={show}
      onClose={onClose}
      title={
        answer === ModelVersionAnswers.ACCEPT
          ? 'Are you sure you want to approve the version?'
          : 'Are you sure you want to disapprove the version?'
      }
      buttons={[
        {
          label: 'Confirm',
          color: 'primary',
          onClick: onConfirm,
          loading: loadingConfirm,
          disabled: loadingConfirm,
        },
        {
          label: 'Cancel',
          onClick: onClose,
          disabled: loadingConfirm,
        },
      ]}
    >
      <p>
        {answer === ModelVersionAnswers.ACCEPT
          ? 'The model will be updated and the author of the version will be notified.'
          : 'The version will be canceled and the author of the version will be notified.'}
      </p>
    </Modal>
  )
}

export default ModalConfirm
