import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import Alert from '../../Components/Alert'
import Button from '../../Components/Button'
import Card from '../../Components/Card'
import FormField from '../../Components/FormField'
import LayoutPublic from '../../Components/LayoutPublic'
import { useAuth } from '../../Contexts/AuthContext'
import getFormikFieldProps from '../../Utils/GetFormikFieldProps'
import handleGraphQLErrors from '../../Utils/HandleGraphQLErrors'

interface LoginState {
  from?: string
}

const Login: React.FC = () => {
  const history = useHistory<LoginState>()
  const { signIn, user, loadingUser } = useAuth()
  const [generalError, setGeneralError] = useState<string>()
  const [isFirstMount, setIsFirstMount] = useState(true)

  useEffect(() => {
    if (isFirstMount && !loadingUser) {
      setIsFirstMount(false)
      if (user) {
        history.push('/')
      }
    }
  }, [history, isFirstMount, loadingUser, user])

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Please write a valid email address.')
        .required('Please enter your email address.'),
      password: Yup.string().required('Please enter your email password.'),
    }),
    onSubmit: async ({ email, password }) => {
      try {
        setGeneralError(undefined)
        await signIn(email, password)
        const redirectTo = history.location.state?.from || '/'
        history.push(redirectTo)
      } catch (error) {
        const errorText = handleGraphQLErrors(
          error,
          'Failed on SignIn. Try again.',
          formik
        )
        if (errorText) {
          setGeneralError(errorText)
        }
      }
    },
  })

  return (
    <LayoutPublic>
      <Card>
        <Card.Body className='login-card-body'>
          <p className='login-box-msg'>Sign in to start your session</p>
          <form noValidate onSubmit={formik.handleSubmit}>
            <FormField
              {...getFormikFieldProps(formik, 'email')}
              type='email'
              placeholder='Email'
              append={<span className='fas fa-envelope' />}
            />
            <FormField
              {...getFormikFieldProps(formik, 'password')}
              type='password'
              placeholder='Password'
              append={<span className='fas fa-lock' />}
            />
            {generalError && (
              <Alert isDismissible onDismiss={() => setGeneralError(undefined)}>
                {generalError}
              </Alert>
            )}
            <div className='row'>
              <div className='col-4 ml-auto'>
                <Button
                  color='primary'
                  disabled={formik.isValidating || formik.isSubmitting}
                  loading={formik.isValidating || formik.isSubmitting}
                  block
                >
                  Sign In
                </Button>
              </div>
            </div>
          </form>
        </Card.Body>
      </Card>
    </LayoutPublic>
  )
}

export default Login
