import React from 'react'

export interface Props {
  prepend?: React.ReactNode
  prependIsText?: boolean
  append?: React.ReactNode
  appendIsText?: boolean
}

const InputGroup: React.FC<Props> = ({
  children,
  append,
  appendIsText = true,
  prepend,
  prependIsText = true,
}) => {
  const prependElement = prepend ? (
    <div className='input-group-prepend'>
      {prependIsText ? (
        <div className='input-group-text'>{prepend}</div>
      ) : (
        prepend
      )}
    </div>
  ) : undefined
  const appendElement = append ? (
    <div className='input-group-append'>
      {appendIsText ? <div className='input-group-text'>{append}</div> : append}
    </div>
  ) : undefined
  return (
    <div className='input-group'>
      {prependElement}
      {children}
      {appendElement}
    </div>
  )
}

export default InputGroup
