import React from 'react'

interface Props {
  title?: string
  titleIcon?: React.ReactNode
}

export type HeaderComponent = React.FC<Props>

const Header: HeaderComponent = ({ children, title, titleIcon }) => (
  <div className='card-header'>
    {(title || titleIcon) && (
      <h3 className='card-title'>
        {titleIcon}
        {title}
      </h3>
    )}
    {children && <div className='card-tools'>{children}</div>}
  </div>
)

export default Header
