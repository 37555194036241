import { useFormik } from 'formik'
import React, { useCallback } from 'react'
import Card from '../../../../Components/Card'
import {
  OrderDirection,
  SearchableFilter,
} from '../../../../Interfaces/SearchableFilter'
import getFormikFieldProps from '../../../../Utils/GetFormikFieldProps'
import FormField from '../../../../Components/FormField'
import Button from '../../../../Components/Button'
import Select from '../../../../Components/Select'
import {
  Transaction,
  TransactionStatus,
  TransactionTypes,
} from '../../../../Interfaces/Transaction'
import transactionStatusReadable from '../../../../Utils/TransactionStatusReadable'
import DateRangeField, { Range } from '../../../../Components/DateRangeField'
import transactionTypeReadable from '../../../../Utils/TransactionTypeReadable'

export type TransactionSearchableFilter = SearchableFilter<Transaction>

interface Props {
  initialValues: FilterFormFields
  onUpdateFilter: (filters: FilterFormFields) => void
}

export interface FilterFormFields {
  direction: OrderDirection
  orderBy: keyof Transaction
  period: Range
  status: string[]
  types: string[]
}

const TransactionsFilter: React.FC<Props> = ({
  initialValues,
  onUpdateFilter,
}) => {
  const formik = useFormik<FilterFormFields>({
    initialValues,
    onSubmit: async values => {
      onUpdateFilter(values)
    },
  })
  const resetForm = () => {
    formik.resetForm()
    onUpdateFilter(initialValues)
  }
  const { setFieldValue } = formik
  const handleChangePeriod = useCallback(
    range => setFieldValue('period', range),
    [setFieldValue]
  )
  return (
    <Card collapsed>
      <Card.Header title='Filter'>
        <button
          type='button'
          className='btn btn-tool'
          data-card-widget='collapse'
          data-toggle='tooltip'
          title='Collapse'
        >
          <i className='fas fa-plus' />
        </button>
      </Card.Header>
      <Card.Body>
        <form noValidate onSubmit={formik.handleSubmit}>
          <div className='row'>
            <DateRangeField
              {...formik.getFieldProps('period')}
              onChange={handleChangePeriod}
              label='Period'
              defaultPredefinedPeriod='month'
              className='col-6'
            />
            <FormField
              {...getFormikFieldProps(formik, 'types')}
              select
              label='Types:'
              className='col'
              multiple
            >
              <Select.Option value=''>All</Select.Option>
              {Object.values(TransactionTypes).map(type => (
                <Select.Option value={type} key={type}>
                  {transactionTypeReadable(type)}
                </Select.Option>
              ))}
            </FormField>
          </div>
          <div className='row'>
            <FormField
              {...getFormikFieldProps(formik, 'status')}
              select
              label='Status:'
              className='col'
              multiple
            >
              <Select.Option value=''>All</Select.Option>
              {Object.values(TransactionStatus).map(status => (
                <Select.Option value={status} key={status}>
                  {transactionStatusReadable(status)}
                </Select.Option>
              ))}
            </FormField>
            <FormField
              {...getFormikFieldProps(formik, 'orderBy')}
              select
              label='Order by:'
              className='col'
            >
              <Select.Option value='createdAt'>Creation date</Select.Option>
              <Select.Option value='updatedAt'>Update date</Select.Option>
              <Select.Option value='value'>Value</Select.Option>
            </FormField>
            <FormField
              {...getFormikFieldProps(formik, 'direction')}
              select
              label='Order direction:'
              className='col'
            >
              <Select.Option value='ASC'>Ascending</Select.Option>
              <Select.Option value='DESC'>Descending</Select.Option>
            </FormField>
          </div>
          <Button
            color='primary'
            disabled={formik.isValidating || formik.isSubmitting}
            loading={formik.isValidating || formik.isSubmitting}
            className='mr-2'
          >
            Search
          </Button>
          <Button
            color='secondary'
            disabled={formik.isValidating || formik.isSubmitting}
            loading={formik.isValidating || formik.isSubmitting}
            onClick={resetForm}
            type='button'
          >
            Reset
          </Button>
        </form>
      </Card.Body>
    </Card>
  )
}

export default TransactionsFilter
