import { gql, MutationTuple, useMutation } from '@apollo/client'
import { ModelVersionAnswers } from '../Interfaces/ModelVersion'

const DO_ANSWER = gql`
  mutation answerModelVersion(
    $modelId: ID!
    $modelVersionId: ID!
    $versionCount: Int!
    $answer: ModelVersionAnswers!
  ) {
    answerModelVersion(
      modelId: $modelId
      modelVersionId: $modelVersionId
      versionCount: $versionCount
      answer: $answer
    )
  }
`

interface AnswerModelVersionVars {
  modelId: string
  modelVersionId: string
  versionCount: number
  answer: ModelVersionAnswers
}

const useAnswerModelVersion = (): MutationTuple<
  boolean,
  AnswerModelVersionVars
> => {
  return useMutation(DO_ANSWER)
}

export default useAnswerModelVersion
