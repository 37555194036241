import { gql, QueryResult, useQuery } from '@apollo/client'
import { SearchableFilter } from '../Interfaces/SearchableFilter'
import { Transaction, TransactionsSearchable } from '../Interfaces/Transaction'

const GET_TRANSACTIONS = gql`
  query transactionsSearchable($filter: SearchableFilter) {
    transactionsSearchable(filter: $filter) {
      items {
        id
        status
        type
        value
        gatewayFee
        netValue
        balanceAfter
        description
        createdAt
        updatedAt
        withdrawDetails {
          type
          email
          bankAccountType
          bankName
          agency
          account
          name
          cpf
          key
          pixName
          institution
        }
        associatedUser {
          id
          name
        }
      }
      totalCount
    }
  }
`

interface GetTransactionsData {
  transactionsSearchable: TransactionsSearchable
}

export interface GetTransactionsVars {
  filter?: SearchableFilter<Transaction>
}

const useTransactionsSearchable = (
  variables?: GetTransactionsVars
): QueryResult<GetTransactionsData> => {
  return useQuery<GetTransactionsData, GetTransactionsVars>(GET_TRANSACTIONS, {
    variables,
  })
}

export default useTransactionsSearchable
