import { Photo } from './Photo'

export enum ClubStatus {
  'CREATED' = 'CREATED',
  'AWAITING_REVIEW' = 'AWAITING_REVIEW',
  'PUBLISHED' = 'PUBLISHED',
}
export enum SocialMediasTypes {
  'YOUTUBE' = 'YOUTUBE',
  'FACEBOOK' = 'FACEBOOK',
  'INSTAGRAM' = 'INSTAGRAM',
  'ARTSTATION' = 'ARTSTATION',
}
export interface SocialMedia {
  type: SocialMediasTypes
  link: string
}
export interface Benefit {
  label: string
}
export interface Tier {
  id: string
  title: string
  priceRaw: number
  price: number
  priceWithFee: number
  image?: Photo
  description: string
  benefits: Benefit[]
}
export interface GalleryImage {
  image: Photo
  description?: string
}
export interface Club {
  id: string
  name: string
  slug: string
  owner: string
  status: ClubStatus
  description: string
  tags: string[]
  socialMedias: SocialMedia[]
  sensitiveContent: boolean
  tiers: Tier[]
  galleryDescription?: string
  galleryImages?: GalleryImage[]
  profileImage?: Photo
  coverImage: Photo
}
