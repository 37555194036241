import { FetchResult, gql } from '@apollo/client'
import client from './ApolloClient'

const DO_LOGOUT = gql`
  mutation {
    clearSession(loginType: ADMIN)
  }
`

const clearSession = async (): Promise<FetchResult<boolean>> => {
  return client.mutate<boolean>({
    mutation: DO_LOGOUT,
  })
}

export default clearSession
