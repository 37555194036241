import React from 'react'
import { Link } from 'react-router-dom'

interface Props {
  to?: string
  onClick?: React.MouseEventHandler
}

export type ItemComponent = React.FC<Props>

const Item: ItemComponent = ({ children, to, onClick }) => {
  if (to) {
    return (
      <Link to={to} onClick={onClick} className='dropdown-item'>
        {children}
      </Link>
    )
  }
  return (
    <button className='dropdown-item' onClick={onClick}>
      {children}
    </button>
  )
}

export default Item
