import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Alert from '../../Components/Alert'
import Card from '../../Components/Card'
import ContentHeader from '../../Components/ContentHeader'
import Layout from '../../Components/Layout'
import Loading from '../../Components/Loading'
import PaginationFooter from '../../Components/PaginationFooter'
import { Model } from '../../Interfaces/Model'
import { PaginationInfo } from '../../Interfaces/PaginationInfo'
import { OrderDirection, IWhereObject } from '../../Interfaces/SearchableFilter'
import useModelsSearchable from '../../Services/ModelsSearchable'
import ModelsFilter, { ModelSearchableFilter } from './components/ModelsFilter'
import ModelsList from './components/ModelsList'

interface Filter {
  skip: number
  limit: number
  direction: OrderDirection
  orderBy: keyof Model
  where: IWhereObject
  hasPendingVersion?: boolean
}

const initialFilter: Filter = {
  skip: 0,
  limit: 20,
  direction: OrderDirection.DESC,
  orderBy: 'createdAt',
  where: {},
}

interface ProjectsState {
  successMessage?: string
}

const Projects: React.FC = () => {
  const [filter, setFilter] = useState(initialFilter)
  const { data, loading, error } = useModelsSearchable({
    filter: {
      skip: filter.skip,
      limit: filter.limit,
      direction: filter.direction,
      orderBy: filter.orderBy,
      where: filter.where,
    },
    hasPendingVersion: filter.hasPendingVersion,
    filterModelsVersions: { where: { status: 'CREATED' } },
  })

  const onChangePage = ({ skip, limit }: PaginationInfo) => {
    setFilter({
      ...filter,
      skip,
      limit,
    })
  }

  const onChangeLimit = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const limit = parseInt(e.target.value, 10)
    setFilter({
      ...filter,
      skip: 0,
      limit,
    })
  }

  const onUpdateFilter = (newFilters: ModelSearchableFilter) => {
    setFilter({
      ...filter,
      ...newFilters,
      skip: 0,
    })
  }

  const models = data?.modelsSearchable.items || []
  const total = data?.modelsSearchable.totalCount || 0

  const [successMessage, setSuccessMessage] = useState<string>()
  const location = useLocation<ProjectsState>()
  const history = useHistory()
  useEffect(() => {
    history.replace(location.pathname)
    const message = location.state?.successMessage
    if (message) {
      setSuccessMessage(message)
    }
  }, [history, location.pathname, location.state])
  const onDismissSuccess = () => {
    setSuccessMessage(undefined)
  }

  return (
    <Layout>
      <ContentHeader title='Projects' />
      <section className='content'>
        {successMessage && (
          <Alert color='success' isDismissible onDismiss={onDismissSuccess}>
            {successMessage}
          </Alert>
        )}
        <ModelsFilter
          defaultOrderBy={initialFilter.orderBy}
          defaultDirection={initialFilter.direction}
          onUpdateFilter={onUpdateFilter}
        />
        {loading ? (
          <Loading />
        ) : (
          <>
            {error ? (
              <Alert>An error occurred while querying the data.</Alert>
            ) : (
              <Card>
                <Card.Body className='p-0'>
                  <ModelsList models={models} />
                </Card.Body>
                <Card.Footer>
                  <PaginationFooter
                    limit={filter.limit}
                    skip={filter.skip}
                    total={total}
                    totalShowing={models.length}
                    onChangeLimit={onChangeLimit}
                    onChangePage={onChangePage}
                  />
                </Card.Footer>
              </Card>
            )}
          </>
        )}
      </section>
    </Layout>
  )
}
export default Projects
