import React from 'react'
import { ModelStatus } from '../../Interfaces/Model'
import statusReadable from '../../Utils/StatusReadable'
import { ClubStatus } from '../../Interfaces/Club'

interface Props {
  status: ModelStatus | ClubStatus
}

const StatusBadge: React.FC<Props> = ({ status }) => {
  const getColorClass = (): string => {
    switch (status) {
      case ModelStatus.CANCELED:
        return 'badge-danger'
      case ModelStatus.FINISHED:
        return 'badge-success'
      case ClubStatus.AWAITING_REVIEW:
        return 'badge-danger'
      case ClubStatus.PUBLISHED:
        return 'badge-success'

      default:
        return 'badge-info'
    }
  }

  return (
    <span className={`badge ${getColorClass()}`}>{statusReadable(status)}</span>
  )
}

export default StatusBadge
