import { gql, MutationTuple, useMutation } from '@apollo/client'
import { TransactionInput } from '../Interfaces/Transaction'

const DO_INSERT = gql`
  mutation createTransaction($transaction: TransactionInput!) {
    createTransaction(transaction: $transaction) {
      id
    }
  }
`

interface CreateTransactionVars {
  transaction: TransactionInput
}

const useCreateTransaction = (): MutationTuple<
  boolean,
  CreateTransactionVars
> => {
  return useMutation(DO_INSERT)
}

export default useCreateTransaction
