type AccessToken = {
  token: string
  expiresIn: Date
}

let accessToken: AccessToken | null = null

export const getAccessToken = (): AccessToken | null => accessToken

export const setAccessToken = (newAccessToken: AccessToken | null): void => {
  accessToken = newAccessToken
}
