import { TransactionStatus } from '../Interfaces/Transaction'

const transactionStatusReadable = (status: TransactionStatus): string => {
  return {
    CREATED: 'Created',
    COMPLETED: 'Completed',
    CANCELED: 'Canceled',
  }[status]
}

export default transactionStatusReadable
