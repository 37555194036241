import { PublicUser } from './PublicUser'
import { SearchableResult } from './SearchableResult'

export enum TransactionStatus {
  'CREATED' = 'CREATED',
  'CANCELED' = 'CANCELED',
  'COMPLETED' = 'COMPLETED',
}

export enum TransactionTypes {
  'CREDITS_PURCHASE' = 'CREDITS_PURCHASE',
  'PURCHASE' = 'PURCHASE',
  'CHARGEBACK' = 'CHARGEBACK',
  'WITHDRAW' = 'WITHDRAW',
  'WITHDRAW_FEE' = 'WITHDRAW_FEE',
  'COLLECTION' = 'COLLECTION',
  'COLLECTION_CHARGEBACK' = 'COLLECTION_CHARGEBACK',
  'EXPENSE' = 'EXPENSE',
  'REVENUE' = 'REVENUE',
}

type WithdrawDetails = {
  type: string
  email?: string
  bankAccountType?: string
  bankName?: string
  agency?: string
  account?: string
  name?: string
  cpf?: string
  key?: string
  pixName?: string
  institution?: string
}
export interface Transaction {
  id: string
  status: TransactionStatus
  type: TransactionTypes
  value: number
  gatewayFee?: number
  netValue: number
  balanceAfter?: number
  description: string
  associatedUser?: PublicUser
  createdAt: string
  updatedAt: string
  withdrawDetails?: WithdrawDetails
}

export type TransactionsSearchable = SearchableResult<Transaction>

export interface TransactionsResults {
  previousBalance: number
  earnings: number
  predictedEarnings: number
  expenses: number
  predictedExpenses: number
  balance: number
  predictedBalance: number
}
export interface TransactionInput {
  status: TransactionStatus
  type: TransactionTypes
  value: number
  description: string
  paymentDate: string
}
