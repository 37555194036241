import React from 'react'
import { VideoInfo } from '../../../../Interfaces/VideoInfo'

interface Props {
  newVideo?: VideoInfo
  oldVideo?: VideoInfo
}

const VideoCompare: React.FC<Props> = ({ newVideo, oldVideo }) => {
  const isWarning = newVideo?.embedUrl !== oldVideo?.embedUrl
  return (
    <div className={`col ${isWarning ? 'border border-warning rounded' : ''}`}>
      <p className='mb-2'>
        <strong>Video</strong>
      </p>
      <p className='m-0'>How will it be</p>
      {newVideo ? (
        <a href={newVideo?.embedUrl} target='_blank' rel='noreferrer'>
          <img src={newVideo?.thumbUrl} alt='Video thumb' width={115} />
        </a>
      ) : (
        <p>
          <small>Without video.</small>
        </p>
      )}
      <p className='m-0 mt-2'>How was</p>
      {oldVideo ? (
        <a href={oldVideo?.embedUrl} target='_blank' rel='noreferrer'>
          <img src={oldVideo?.thumbUrl} alt='Video thumb' width={115} />
        </a>
      ) : (
        <p>
          <small>Without video.</small>
        </p>
      )}
    </div>
  )
}

export default VideoCompare
