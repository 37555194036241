import { ApolloError } from '@apollo/client'
import { FormikContextType } from 'formik'
import getGraphQLErrors from './GetGraphQLErrors'

const handleGraphQLErrors = <T>(
  error: ApolloError | Error,
  defaultError: string,
  formik: FormikContextType<T>
): string | undefined => {
  const errors = getGraphQLErrors(error, defaultError)
  let generalError: string | undefined
  Object.entries(errors).forEach(entry => {
    if (entry[0] === '') {
      const [, errorValues] = entry
      ;[generalError] = errorValues
    } else {
      formik.setFieldError(entry[0], entry[1][0])
    }
  })
  return generalError
}

export default handleGraphQLErrors
