import React from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useAuth } from '../../../../Contexts/AuthContext'
import client from '../../../../Services/ApolloClient'
import useNotificationsService from '../../../../Services/NotificationsService'

const Header: React.FC = () => {
  const history = useHistory()
  const { signOut } = useAuth()
  const handleLogout = async () => {
    await signOut()
    client.clearStore()
    history.push('/login')
  }
  const { data } = useNotificationsService({
    limit: 10,
    skip: 0,
  })
  const hasNotif = data?.notifications.hasUnread
  const total =
    data &&
    data.notifications.notifications.filter(notif => notif.readedAt === null)
  return (
    <nav className='main-header navbar navbar-expand navbar-white navbar-light'>
      <ul className='navbar-nav'>
        <li className='nav-item'>
          <a className='nav-link' data-widget='pushmenu' href='#' role='button'>
            <i className='fas fa-bars' />
          </a>
        </li>
      </ul>
      <ul className='navbar-nav ml-auto'>
        {/* Notifications Dropdown Menu */}
        <li className='nav-item dropdown'>
          <a className='nav-link' data-toggle='dropdown' href='#'>
            <i className='far fa-bell' />
            {hasNotif && (
              <span className='badge badge-warning navbar-badge'>
                {total?.length}
              </span>
            )}
          </a>

          <div className='dropdown-menu dropdown-menu-lg dropdown-menu-right'>
            <div className='dropdown-divider' />

            <Link to='/notifications' className='dropdown-item dropdown-footer'>
              See All Notifications
            </Link>
          </div>
        </li>
        <li className='nav-item d-none d-sm-inline-block'>
          <a href='#' className='nav-link' onClick={handleLogout}>
            <span className='fas fa-sign-out-alt mr-1' />
            Sign Out
          </a>
        </li>
      </ul>
    </nav>
  )
}
export default Header
