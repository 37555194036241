import React from 'react'

export interface ButtonProps {
  size?: 'xs' | 'sm' | 'lg'
  block?: boolean
  disabled?: boolean
  loading?: boolean
  flat?: boolean
  color?: 'primary' | 'secondary' | 'success' | 'info' | 'danger' | 'warning'
  type?: 'button' | 'submit' | 'reset'
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  className?: string
  'data-toggle'?: string
}

const Button: React.FC<ButtonProps> = ({
  children,
  size,
  block = false,
  disabled = false,
  loading = false,
  flat = false,
  color,
  type = 'submit',
  onClick,
  className = '',
  'data-toggle': dataToggle,
}) => {
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (!disabled && onClick) onClick(event)
  }
  return (
    <button
      type={type}
      className={[
        'btn',
        `btn-${color || 'default'}`,
        size ? `btn-${size}` : '',
        block ? 'btn-block' : '',
        disabled ? 'disabled' : '',
        flat ? 'btn-flat' : '',
        className,
      ].join(' ')}
      onClick={handleClick}
      data-toggle={dataToggle}
    >
      {loading && <i className='fas fa-circle-notch fa-spin mr-2' />}
      {children}
    </button>
  )
}

export default Button
