import { DateTime } from 'luxon'
import React, { useRef, useState, useEffect } from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import FormField from '../FormField'
import Select from '../Select'
import styles from './DateRangeField.module.scss'

export type Range = {
  start: DateTime
  end: DateTime
}

type PredefinedPeriod = 'today' | 'week' | 'month' | 'custom'

interface Props {
  value: Range
  onChange: (range: Range) => void
  label?: string
  defaultPredefinedPeriod?: PredefinedPeriod
  className?: string
}

const formatDate = (date: Date, format: string): string => {
  return DateTime.fromJSDate(date).toFormat(format)
}

const parseDate = (str: string, format: string): Date | void => {
  const date = DateTime.fromFormat(str, format)
  if (date.isValid) {
    return date.toJSDate()
  }
  return undefined
}

const DateRangeField: React.FC<Props> = ({
  value,
  onChange,
  label,
  defaultPredefinedPeriod,
  className,
}) => {
  const endInputEl = useRef<DayPickerInput>(null)
  const [predefined, setPredefined] = useState(defaultPredefinedPeriod)
  useEffect(() => {
    let start: DateTime | undefined
    let end: DateTime | undefined
    if (predefined === 'today') {
      start = DateTime.utc()
      end = DateTime.utc()
    }
    if (predefined === 'week' || predefined === 'month') {
      start = DateTime.now().startOf(predefined)
      end = DateTime.now().endOf(predefined)
    }
    if (start != null && end != null) {
      onChange({
        start,
        end,
      })
    }
  }, [onChange, predefined])
  const handleChangePredefined = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { value: newPredefined } = e.target
    setPredefined(newPredefined as PredefinedPeriod)
  }
  const handleChangeStart = (day: Date) => {
    if (day) {
      onChange({
        ...value,
        start: DateTime.fromJSDate(day),
      })
      setPredefined('custom')
    }
  }
  const handleChangeEnd = (day: Date) => {
    if (day) {
      onChange({
        ...value,
        end: DateTime.fromJSDate(day),
      })
      setPredefined('custom')
    }
  }
  const start = value.start.toJSDate()
  const end = value.end.toJSDate()
  const modifiers = { start, end }
  return (
    <div className={`${styles.wrap} ${className}`}>
      {label && <label>{label}</label>}
      <div className='row'>
        <FormField
          select
          className='col'
          value={predefined}
          onChange={handleChangePredefined}
        >
          <Select.Option value='today'>Today</Select.Option>
          <Select.Option value='week'>This week</Select.Option>
          <Select.Option value='month'>This month</Select.Option>
          <Select.Option value='custom'>Custom</Select.Option>
        </FormField>
        <div className='col'>
          <DayPickerInput
            value={start}
            placeholder='From'
            format='yyyy-MM-dd'
            formatDate={formatDate}
            parseDate={parseDate}
            dayPickerProps={{
              selectedDays: [start, { from: start, to: end }],
              disabledDays: { after: end },
              toMonth: end,
              modifiers,
              numberOfMonths: 2,
              onDayClick: () => endInputEl.current?.getInput().focus(),
            }}
            inputProps={{
              className: 'form-control',
            }}
            onDayChange={handleChangeStart}
          />
        </div>
        <div className={`col-auto ${styles.dateSeparator}`}>—</div>
        <div className={`col ${styles.inputEnd}`}>
          <DayPickerInput
            ref={endInputEl}
            value={end}
            placeholder='To'
            format='yyyy-MM-dd'
            formatDate={formatDate}
            parseDate={parseDate}
            dayPickerProps={{
              selectedDays: [start, { from: start, to: end }],
              disabledDays: { before: start },
              modifiers,
              month: start,
              fromMonth: start,
              numberOfMonths: 2,
            }}
            inputProps={{
              className: 'form-control',
            }}
            onDayChange={handleChangeEnd}
          />
        </div>
      </div>
    </div>
  )
}

export default DateRangeField
