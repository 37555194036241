import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import ModalConfirmApprove from '../ModalConfirmApprove'
import Alert from '../../../../Components/Alert'
import Button from '../../../../Components/Button'
import Card from '../../../../Components/Card'
import FormField from '../../../../Components/FormField'
import { Club } from '../../../../Interfaces/Club'
import getGraphQLErrors from '../../../../Utils/GetGraphQLErrors'
import useClubApproval from '../../../../Services/ClubApproval'
import formatCurrency from '../../../../Utils/FormatCurrency'

interface Props {
  club: Club
}

const ShowDetail: React.FC<Props> = ({ club }) => {
  const [clubApproval, { loading }] = useClubApproval()
  const history = useHistory()

  const [showModal, setShowModal] = useState(false)
  const openModal = () => setShowModal(true)
  const closeModal = () => setShowModal(false)

  const [generalErrors, setGeneralErrors] = useState<string>()
  const onConfirmApproval = async (): Promise<void> => {
    try {
      setGeneralErrors('')
      await clubApproval({
        variables: {
          clubId: club.id,
        },
        update: async cache => {
          await cache.reset()
        },
      })
      history.push('/clubs', {
        successMessage: 'Club approved successfully',
      })
    } catch (error) {
      const defaultError = 'Failed. Try again.'
      const graphqlErrors = getGraphQLErrors(error, defaultError)
      const errorsArray = Object.values(graphqlErrors)
      setGeneralErrors(errorsArray[0]?.[0] || defaultError)
    }
  }

  return (
    <>
      <Card>
        <Card.Header title='Basic data' />
        <Card.Body>
          <div className='row mb-4'>
            <div className='col-3'>
              <p className='mb-2 font-weight-bold'>Profile Image</p>
              <img
                src={
                  club.profileImage?.photo ||
                  'https://co3d.art/assets/img/default-picture.jpg'
                }
                alt={club.name}
                className='img-fluid'
              />
            </div>
            <div className='col-9'>
              <p className='mb-2 font-weight-bold'>Cover Image</p>
              <img
                src={club.coverImage.photo}
                alt={club.name}
                className='img-fluid'
              />
            </div>
          </div>
          <div className='row'>
            <FormField
              label='Name'
              value={club.name}
              disabled
              className='col'
            />
            <FormField
              label='Slug'
              value={club.slug}
              disabled
              className='col'
            />
            <FormField
              label='Owner'
              value={club.owner}
              disabled
              className='col'
            />
          </div>
          <div className='row'>
            <FormField
              label='Description'
              value={club.description}
              disabled
              className='col'
              multiple
            />
            <FormField
              label='Tags'
              value={
                club.tags.length > 0 ? club.tags.join(', ') : 'Without tags.'
              }
              disabled
              className='col'
              multiple
            />
          </div>
          <div className='row'>
            <FormField
              label='Sensitive content'
              value={club.sensitiveContent ? 'Yes' : 'No'}
              disabled
              className='col'
            />
          </div>
          <div className='row'>
            {club.socialMedias &&
              club.socialMedias.map((social, index) => (
                <FormField
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  label={social.type}
                  value={social.link}
                  disabled
                  className='col'
                />
              ))}
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header title='Tiers' />
        <Card.Body>
          {club.tiers.map(tier => (
            <Card key={tier.id}>
              <div className='card-header'>
                <FormField
                  label='Title'
                  value={tier.title}
                  disabled
                  className='col'
                />
              </div>
              <Card.Body>
                <div className='row'>
                  {tier.image && (
                    <div className='col mb-4'>
                      <img
                        src={tier.image.photo}
                        alt={tier.title}
                        className='img-fluid'
                      />
                    </div>
                  )}
                  <FormField
                    label='Description'
                    value={tier.description}
                    disabled
                    className='col'
                  />
                  <FormField
                    label='Price on site'
                    value={
                      tier.priceWithFee !== 0
                        ? formatCurrency(tier.priceWithFee)
                        : 'Free'
                    }
                    disabled
                    className='col'
                  />
                  <FormField
                    label='Price without fees'
                    value={
                      tier.priceRaw !== 0
                        ? formatCurrency(tier.priceRaw)
                        : 'Free'
                    }
                    disabled
                    className='col'
                  />
                </div>
                <h5>Benefits</h5>
                {tier.benefits.map((benefit, index) => (
                  <FormField
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    label='Benefit name'
                    value={benefit.label}
                    disabled
                  />
                ))}
              </Card.Body>
            </Card>
          ))}
        </Card.Body>
      </Card>
      <Card>
        <Card.Header title='Gallery' />
        <Card.Body>
          <div className='row'>
            <FormField
              label='Gallery description'
              value={club.galleryDescription || 'Without gallery description.'}
              disabled
              className='col'
              multiple
            />
          </div>
          {!!club.galleryImages && club.galleryImages.length > 0 ? (
            club.galleryImages.map((image, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index} className='row'>
                <div className='col-3 mb-3'>
                  <p className='mb-2 font-weight-bold'>Image</p>
                  <img
                    src={image.image.photo}
                    alt='Gallery item'
                    className='img-fluid'
                  />
                </div>
                <FormField
                  label='Subtitle'
                  value={image.description || 'Without subtitle.'}
                  disabled
                  className='col'
                  multiple
                />
              </div>
            ))
          ) : (
            <p>Without gallery images.</p>
          )}
        </Card.Body>
      </Card>
      <div className='px-3 pb-3'>
        {generalErrors && <Alert>{generalErrors}</Alert>}
        {club.status === 'AWAITING_REVIEW' && (
          <Button
            color='primary'
            className='mr-2'
            loading={loading}
            disabled={loading}
            onClick={openModal}
          >
            Publish club
          </Button>
        )}
        <Button onClick={() => history.push('/clubs')}>Back</Button>
      </div>
      <ModalConfirmApprove
        show={showModal}
        onClose={closeModal}
        onConfirm={onConfirmApproval}
        loadingConfirm={loading}
      />
    </>
  )
}

export default ShowDetail
