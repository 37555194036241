import React from 'react'
import Modal from '../../../../Components/Modal'

interface Props {
  show: boolean
  onClose: () => void
  onConfirm: () => void
  loadingConfirm?: boolean
}

const ModalConfirmApprove: React.FC<Props> = ({
  show,
  onClose,
  onConfirm,
  loadingConfirm = false,
}) => {
  return (
    <Modal
      show={show}
      onClose={onClose}
      title='Are you sure you want to approve the club?'
      buttons={[
        {
          label: 'Confirm',
          color: 'primary',
          onClick: onConfirm,
          loading: loadingConfirm,
          disabled: loadingConfirm,
        },
        {
          label: 'Cancel',
          onClick: onClose,
          disabled: loadingConfirm,
        },
      ]}
    >
      <p>The club will be updated and the owner will be notified.</p>
    </Modal>
  )
}

export default ModalConfirmApprove
