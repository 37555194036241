/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import Alert from '../../Components/Alert'
import Card from '../../Components/Card'
import ContentHeader from '../../Components/ContentHeader'
import Layout from '../../Components/Layout'
import Loading from '../../Components/Loading'
import useContestsService from '../../Services/ContestsService'
import { PaginationInfo } from '../../Interfaces/PaginationInfo'
import Button from '../../Components/Button'
import Dropdown from '../../Components/Dropdown'
import useContestChangeVisibility from '../../Services/ContestChangeVisibility'
import Select from '../../Components/Select'
import getGraphQLErrors from '../../Utils/GetGraphQLErrors'
import PaginationFooter from '../../Components/PaginationFooter'

interface ContestVars {
  skip: number
  limit: number
}

const initialFilter: ContestVars = {
  skip: 0,
  limit: 20,
}

interface ContestsParams {
  slug: string
}

const Contests: React.FC = () => {
  const [generalErrors, setGeneralErrors] = useState<string>()
  const { slug } = useParams<ContestsParams>()
  const [filter, setFilter] = useState(initialFilter)

  const { data, loading, error } = useContestsService({
    skipEntries: filter.skip,
    limitEntries: filter.limit,
    slug,
    returnNonPublic: true,
  })

  const onChangePage = ({ skip, limit }: PaginationInfo) => {
    setFilter({
      ...filter,
      skip,
      limit,
    })
  }

  const onChangeLimit = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const limit = parseInt(e.target.value, 10)
    setFilter({
      ...filter,
      skip: 0,
      limit,
    })
  }

  const [publicStatus] = useContestChangeVisibility()

  const handleVisibilityClick = async (
    entryId: string,
    entry: boolean
  ): Promise<void> => {
    setGeneralErrors('')

    try {
      await publicStatus({
        variables: {
          contestId: data?.contests.id ? data?.contests.id : '',
          entryId,
          entry: { public: entry },
        },
        update: async cache => {
          await cache.reset()
        },
      })
    } catch (err) {
      const defaultError = 'Failed. Try again.'
      const graphqlErrors = getGraphQLErrors(err, defaultError)
      const errorsArray = Object.values(graphqlErrors)
      setGeneralErrors(errorsArray[0]?.[0] || defaultError)
    }
  }

  const contest = data?.contests.entries || []
  const total = data?.contests.countEntries || 0

  return (
    <Layout>
      <ContentHeader title='Contest participations' />

      <section className='content'>
        {loading ? (
          <Loading />
        ) : (
          <>
            {generalErrors && <Alert>{generalErrors}</Alert>}

            {error ? (
              <Alert>An error occurred while querying the data.</Alert>
            ) : (
              <Card>
                <Card.Body className='p-0'>
                  <table className='table table-striped table-hover projects'>
                    <thead>
                      <tr>
                        <th> </th>
                        <th> </th>
                        <th>Author</th>
                        <th>Model</th>
                        <th>Bought/valid</th>
                        <th>Visible</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {contest.length > 0 ? (
                        contest.map(participation => (
                          <tr key={participation.id}>
                            {slug === 'printandpaint' &&
                            participation.public === false ? (
                              participation.featuredPhoto?.photo ? (
                                participation.authorIsBacker ? (
                                  <td className='greenball'>⊛</td>
                                ) : (
                                  <td className='redball'>⊛</td>
                                )
                              ) : participation.authorIsBacker ? (
                                <td className='blueball'>⊛</td>
                              ) : (
                                <td />
                              )
                            ) : (
                              <td />
                            )}

                            {(slug === 'hype2022part1' ||
                              slug === 'hype2022part2') &&
                            participation.public === false &&
                            participation.featuredPhoto?.photo ? (
                              <td className='greenball'>⊛</td>
                            ) : (
                              <td />
                            )}

                            <td width='30px'>
                              {participation.featuredPhoto && (
                                <a
                                  href={participation.featuredPhoto?.photo}
                                  target='_blank'
                                  rel='noreferrer'
                                >
                                  <img
                                    alt={participation.associatedModel?.name}
                                    className='table-avatar'
                                    src={participation.featuredPhoto?.photo}
                                  />
                                </a>
                              )}
                            </td>
                            <td>{participation.author.name}</td>
                            <td>{participation.associatedModel?.name}</td>
                            <td>
                              {participation.authorIsBacker ? (
                                <span className='badge badge-success'>Yes</span>
                              ) : (
                                <span className='badge badge-danger'>No</span>
                              )}
                            </td>
                            <td>
                              <Select
                                value={participation.public ? 'true' : 'false'}
                                onChange={() =>
                                  handleVisibilityClick(
                                    participation.id,
                                    !participation.public
                                  )
                                }
                              >
                                <Select.Option value='true'>
                                  Visible
                                </Select.Option>
                                <Select.Option value='false'>
                                  Hidden
                                </Select.Option>
                              </Select>
                            </td>
                            <td>
                              <Dropdown>
                                <Button
                                  color='info'
                                  className='dropdown-toggle'
                                  data-toggle='dropdown'
                                >
                                  Actions
                                </Button>
                                <Dropdown.Menu isMenuEnd>
                                  <Dropdown.Item>Detalhes</Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={7} className='text-center'>
                            No participation
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </Card.Body>
                <Card.Footer>
                  <PaginationFooter
                    limit={filter.limit}
                    skip={filter.skip}
                    total={total}
                    totalShowing={contest.length}
                    onChangeLimit={onChangeLimit}
                    onChangePage={onChangePage}
                  />
                </Card.Footer>
              </Card>
            )}
          </>
        )}
      </section>
    </Layout>
  )
}
export default Contests
