import { Setting } from '../Interfaces/Setting'

const applyAdministrativeFee = (
  value: number,
  administrativeFee: number
): number => {
  return value * (1 + administrativeFee / 100)
}

const applyProcessingFee = (
  value: number,
  processingFee: number,
  gatewayFixedFee: number
): number => {
  const priceWithFixedFee = value + gatewayFixedFee
  const finalProcessingFee = priceWithFixedFee * processingFee + gatewayFixedFee

  return value + finalProcessingFee
}

const applyModelingFee = (
  value: number,
  processingFee: number,
  gatewayFixedFee: number
): number => {
  return applyProcessingFee(value * 1.33, processingFee, gatewayFixedFee)
}

const applyFinishedFee = (
  value: number,
  processingFee: number,
  gatewayFixedFee: number
): number => {
  return applyProcessingFee(value * 2, processingFee, gatewayFixedFee)
}

export type QuotaValuesKeys =
  | 'withoutFee'
  | 'RAISING_FUNDS'
  | 'MODELING'
  | 'FINISHED'
  | 'finishedMarketplace'
type QuotaValues = {
  [key in QuotaValuesKeys]: number
}

const getQuotaValue = (
  amount: number,
  totalQuotas: number,
  { administrativeFee, processingFee, gatewayFixedFee }: Setting
): QuotaValues => {
  const price = Math.ceil((amount / totalQuotas) * 100) / 100
  const priceWithFee = applyAdministrativeFee(price, administrativeFee)
  return {
    withoutFee: price,
    RAISING_FUNDS: applyProcessingFee(
      priceWithFee,
      processingFee,
      gatewayFixedFee
    ),
    MODELING: applyModelingFee(priceWithFee, processingFee, gatewayFixedFee),
    FINISHED: applyFinishedFee(priceWithFee, processingFee, gatewayFixedFee),
    finishedMarketplace: applyProcessingFee(
      priceWithFee,
      processingFee,
      gatewayFixedFee
    ),
  }
}

export default getQuotaValue
