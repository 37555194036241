import React from 'react'
import { Club } from '../../../../Interfaces/Club'
import StatusBadge from '../../../../Components/StatusBadge'
import Button from '../../../../Components/Button'
import Dropdown from '../../../../Components/Dropdown'

interface Props {
  clubs: Club[]
}

const ClubList: React.FC<Props> = ({ clubs }) => {
  return (
    <table className='table table-striped table-hover projects'>
      <thead>
        <tr>
          <th style={{ width: '5%' }}>#</th>
          <th>Name</th>
          <th>Slug</th>
          <th>Owner</th>
          <th className='text-center'>Status</th>
          <th aria-label='Actions' />
        </tr>
      </thead>
      <tbody>
        {clubs.length > 0 ? (
          clubs.map(club => (
            <tr key={club.id}>
              <td>
                {club.profileImage ? (
                  <a
                    href={club.profileImage.photo}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <img
                      alt='avatar'
                      className='table-avatar'
                      src={club.profileImage.photo}
                    />
                  </a>
                ) : (
                  <img
                    alt='avatar'
                    className='table-avatar'
                    src='https://co3d.art/assets/img/default-picture.jpg'
                  />
                )}
              </td>
              <td>{club.name}</td>
              <td>{club.slug}</td>
              <td>{club.owner}</td>
              <td>
                <StatusBadge status={club.status} />
              </td>
              <td className='text-right'>
                <Dropdown>
                  <Button
                    color='info'
                    className='dropdown-toggle'
                    data-toggle='dropdown'
                  >
                    Actions
                  </Button>
                  <Dropdown.Menu isMenuEnd>
                    <Dropdown.Item to={`/clubs/${club.id}`}>
                      Details
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={6} className='text-center'>
              No clubs found.
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default ClubList
