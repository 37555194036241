import React from 'react'
import { PaginationInfo } from '../../Interfaces/PaginationInfo'
import PaginationItem from './components/PaginationItem'

interface Props {
  className?: string
  pagination: PaginationInfo
  total: number
  onChangePage: (pagination: PaginationInfo) => void
}

const Pagination: React.FC<Props> = ({
  className,
  pagination,
  total,
  onChangePage,
}) => {
  const totalPages = Math.ceil(total / pagination.limit)
  const currentPage = pagination.skip / pagination.limit + 1
  const onClickPage = (page: number) => {
    onChangePage({
      skip: (page - 1) * pagination.limit,
      limit: pagination.limit,
    })
  }
  const pagesOnScreen = 5
  const otherPagesQty = pagesOnScreen - 1
  const halfPages = Math.floor(pagesOnScreen / 2)
  const countToLastPage = totalPages - currentPage
  const pages = []
  let firstPage =
    countToLastPage > halfPages
      ? currentPage - halfPages
      : totalPages - otherPagesQty
  if (firstPage < 1) firstPage = 1
  for (
    let i = firstPage;
    i <= firstPage + otherPagesQty && i <= totalPages;
    i += 1
  ) {
    pages.push(
      <PaginationItem
        active={i === currentPage}
        key={i}
        onClick={() => onClickPage(i)}
      >
        {i}
      </PaginationItem>
    )
  }
  const lastPage = firstPage + pages.length - 1

  return (
    <ul className={`pagination ${className}`}>
      <PaginationItem
        onClick={() => onClickPage(currentPage - 1)}
        disabled={currentPage === 1}
      >
        ‹
      </PaginationItem>
      {firstPage > 1 && (
        <>
          <PaginationItem onClick={() => onClickPage(1)}>{1}</PaginationItem>
          {firstPage > 2 && <PaginationItem disabled>...</PaginationItem>}
        </>
      )}
      {pages}
      {lastPage < totalPages && (
        <>
          {lastPage < totalPages - 1 && (
            <PaginationItem disabled>...</PaginationItem>
          )}
          <PaginationItem onClick={() => onClickPage(totalPages)}>
            {totalPages}
          </PaginationItem>
        </>
      )}
      <PaginationItem
        onClick={() => onClickPage(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        ›
      </PaginationItem>
    </ul>
  )
}

export default Pagination
