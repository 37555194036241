import React from 'react'
import useTransactionsResults from '../../../../Services/TransactionsResults'
import formatCurrency from '../../../../Utils/FormatCurrency'
import Loading from '../../../../Components/Loading'
import Alert from '../../../../Components/Alert'
import styles from './TransactionTotalizer.module.scss'

interface Props {
  startDate: string
  endDate: string
}

const TransactionTotalizer: React.FC<Props> = ({ startDate, endDate }) => {
  const { loading, data, error } = useTransactionsResults(startDate, endDate)
  return (
    <div className={`bg-light border-top ${styles.wrap} py-2`}>
      {loading ? (
        <Loading />
      ) : (
        <>
          {error ? (
            <Alert>An error occurred while querying the data.</Alert>
          ) : (
            <table
              className={`table table-borderless table-sm ${styles.tableSummary}`}
            >
              <tbody>
                <tr>
                  <td rowSpan={8} />
                  <td colSpan={2}>
                    <h4>Summary</h4>
                  </td>
                </tr>
                <tr>
                  <th className='border-bottom'>Previous balance:</th>
                  <td className='text-right border-bottom'>
                    <strong>
                      {formatCurrency(data?.previousBalance || 0)}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <th>Earnings:</th>
                  <td className='text-right text-success'>
                    {formatCurrency(data?.earnings || 0)}
                  </td>
                </tr>
                <tr>
                  <th>Predicted earnings:</th>
                  <td className='text-right text-secondary'>
                    {formatCurrency(data?.predictedEarnings || 0)}
                  </td>
                </tr>
                <tr>
                  <th>Expenses:</th>
                  <td className='text-right text-danger'>
                    {formatCurrency(data?.expenses || 0)}
                  </td>
                </tr>
                <tr>
                  <th>Predicted expenses:</th>
                  <td className='text-right text-secondary'>
                    {formatCurrency(data?.predictedExpenses || 0)}
                  </td>
                </tr>
                <tr>
                  <th className='border-top'>Balance:</th>
                  <td className='text-right border-top'>
                    <strong>{formatCurrency(data?.balance || 0)}</strong>
                  </td>
                </tr>
                <tr>
                  <th>Predicted balance:</th>
                  <td className='text-right text-secondary'>
                    {formatCurrency(data?.predictedBalance || 0)}
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </>
      )}
    </div>
  )
}

export default TransactionTotalizer
