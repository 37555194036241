import React from 'react'
import Item, { ItemComponent } from './components/Item'
import Menu, { MenuComponent } from './components/Menu'

type DropdownComponent = React.FC & {
  Menu: MenuComponent
  Item: ItemComponent
}

const Dropdown: DropdownComponent = ({ children }) => (
  <div className='dropdown'>{children}</div>
)
Dropdown.Menu = Menu
Dropdown.Item = Item

export default Dropdown
