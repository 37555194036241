import React from 'react'
import { useParams } from 'react-router-dom'
import Alert from '../../Components/Alert'
import ContentHeader from '../../Components/ContentHeader'
import Layout from '../../Components/Layout'
import Loading from '../../Components/Loading'
import useModelsSearchable from '../../Services/ModelsSearchable'
import VersionForm from './components/VersionForm'

interface RouterParams {
  modelId: string
}

const ModelVersionPending: React.FC = () => {
  const { modelId } = useParams<RouterParams>()
  const { data, loading, error } = useModelsSearchable({
    filter: {
      where: { id: { toId: modelId } },
    },
    filterModelsVersions: { where: { status: 'CREATED' } },
  })
  const model = data?.modelsSearchable.items[0]
  const modelVersion = model?.modelsVersionsSearchable.items[0]
  return (
    <Layout>
      <ContentHeader title='Pending version' />
      <section className='content'>
        {loading ? (
          <Loading />
        ) : (
          <>
            {error || !model || !modelVersion ? (
              <Alert>An error occurred while querying the data.</Alert>
            ) : (
              <VersionForm model={model} modelVersion={modelVersion} />
            )}
          </>
        )}
      </section>
    </Layout>
  )
}

export default ModelVersionPending
