import React, { createContext, useContext } from 'react'
import { Setting } from '../Interfaces/Setting'
import useGetSettings from '../Services/GetSettings'

interface SettingsContextProps {
  settings?: Setting
  loading: boolean
  refetch: () => void
}

const SettingsContext = createContext({} as SettingsContextProps)

export const SettingsProvider: React.FC = ({ children }) => {
  const { data: settings, loading, refetch } = useGetSettings()

  return (
    <SettingsContext.Provider
      value={{
        settings: settings ? settings.getSettings : undefined,
        loading,
        refetch,
      }}
    >
      {children}
    </SettingsContext.Provider>
  )
}

export const useSettings = (): SettingsContextProps => {
  return useContext(SettingsContext)
}
