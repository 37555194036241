import { ApolloError } from '@apollo/client'
import { GraphQLError } from 'graphql'

interface IErrorState {
  [key: string]: string[]
}

interface GraphQLErrorWithState extends GraphQLError {
  state?: IErrorState
}

const getGraphQLErrors = (
  error: ApolloError | Error,
  defaultError: string
): IErrorState => {
  const defaultErrorObj = { '': [defaultError] }
  if (!(error instanceof ApolloError)) {
    return defaultErrorObj
  }
  const { networkError, graphQLErrors } = error
  if (networkError || !graphQLErrors || graphQLErrors.length === 0) {
    return defaultErrorObj
  }
  const firstError = graphQLErrors[0] as GraphQLErrorWithState
  if (!firstError.state) {
    return defaultErrorObj
  }
  return firstError.state
}

export default getGraphQLErrors
