import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { TokenRefreshLink } from 'apollo-link-token-refresh'
import { Session } from '../Interfaces/Session'
import { getAccessToken, setAccessToken } from './AccessToken'
import refreshSession from './RefreshSession'

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
  credentials: 'include',
})

const authLink = setContext((_, { headers }) => {
  const accessToken = getAccessToken()
  return {
    headers: {
      ...headers,
      authorization:
        accessToken && accessToken.token
          ? `Bearer ${accessToken.token}`
          : undefined,
    },
  }
})

const refreshTokenLink = new TokenRefreshLink({
  accessTokenField: 'accessToken',
  isTokenValidOrUndefined: () => {
    const token = getAccessToken()
    if (!token || !token.token) return true
    if (new Date() >= token.expiresIn) return false
    return true
  },
  fetchAccessToken: async () => {
    const result = await refreshSession()
    return result.json()
  },
  handleResponse: (operation, accessTokenField) => (response: Session) => {
    const now = new Date()
    setAccessToken({
      token: response.accessToken,
      expiresIn: new Date(
        now.getTime() + parseInt(response.expiresIn, 10) * 1000
      ),
    })
    return {
      [accessTokenField]: response.accessToken,
    }
  },
  handleError: () => {
    setAccessToken(null)
    document.location.reload()
  },
  handleFetch: () => {},
})

const client = new ApolloClient({
  link: ApolloLink.from([refreshTokenLink, authLink, httpLink]),
  cache: new InMemoryCache(),
})

export default client
