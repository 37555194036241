import React from 'react'
import { Link } from 'react-router-dom'

interface Props {
  title: string
  breadcrumbTitle?: string
  showBreadcrumbHome?: boolean
}

const ContentHeader: React.FC<Props> = ({
  title,
  breadcrumbTitle,
  showBreadcrumbHome = true,
}) => {
  return (
    <div className='content-header'>
      <div className='container-fluid'>
        <div className='row mb-2'>
          <div className='col-sm-6'>
            <h1 className='m-0 text-dark'>{title}</h1>
          </div>
          <div className='col-sm-6'>
            <ol className='breadcrumb float-sm-right'>
              {showBreadcrumbHome && (
                <li className='breadcrumb-item'>
                  <Link to='/'>Home</Link>
                </li>
              )}
              <li className='breadcrumb-item active'>
                {breadcrumbTitle || title}
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContentHeader
