import React, { useState } from 'react'
import Alert from '../../../../Components/Alert'
import Badge from '../../../../Components/Badge'
import { ModelFile } from '../../../../Interfaces/ModelFile'
import useGetModelDownloadUrl from '../../../../Services/GetModelDownloadUrl'

interface Props {
  modelId: string
  label: string
  className?: string
  newItems: ModelFile[]
  oldItems?: ModelFile[]
}

interface FileCompared extends ModelFile {
  color: 'success' | 'secondary' | 'danger'
}

const FilesCompare: React.FC<Props> = ({
  modelId,
  label,
  className,
  newItems,
  oldItems,
}) => {
  const { refetch } = useGetModelDownloadUrl(undefined, true)
  const [errors, setErrors] = useState<string>()
  let isWarning = false
  const newItemsCompared = newItems.map<FileCompared>(item => {
    const isOld = oldItems?.find(oldItem => oldItem.id === item.id)
    if (isOld == null) isWarning = true
    return {
      id: item.id,
      originalName: item.originalName,
      color: isOld == null ? 'success' : 'secondary',
    }
  })
  const oldItemsCompared = oldItems?.map<FileCompared>(item => {
    const isNew = newItems.find(newItem => newItem.id === item.id)
    if (isNew == null) isWarning = true
    return {
      id: item.id,
      originalName: item.originalName,
      color: isNew == null ? 'danger' : 'secondary',
    }
  })
  const handleDownloadFile = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    item: FileCompared,
    fromVersion: boolean
  ) => {
    e.preventDefault()
    setErrors(undefined)
    try {
      const { data } = await refetch({
        modelId,
        fileId: item.id,
        fromVersion,
      })
      window.open(data?.getModelDownloadUrl, '_blank')
    } catch {
      setErrors('Failed to download the file. Try again.')
    }
  }
  return (
    <div
      className={`${className} ${
        isWarning ? 'border border-warning rounded' : ''
      }`}
    >
      {errors && <Alert>{errors}</Alert>}
      <p className='mb-2'>
        <strong>{label}</strong>
      </p>
      <p className='m-0'>How will it be</p>
      <div className='mb-2 h4'>
        {newItemsCompared.length > 0 ? (
          newItemsCompared.map(item => (
            <Badge
              key={item.id}
              color={item.color}
              className='mr-2'
              onClick={e => handleDownloadFile(e, item, true)}
            >
              {item.originalName}
            </Badge>
          ))
        ) : (
          <p style={{ fontSize: 13 }}>Without files.</p>
        )}
      </div>
      <p className='m-0'>How was</p>
      <div className='h4'>
        {oldItemsCompared && oldItemsCompared.length > 0 ? (
          oldItemsCompared.map(item => (
            <Badge
              key={item.id}
              color={item.color}
              className='mr-2'
              onClick={e => handleDownloadFile(e, item, false)}
            >
              {item.originalName}
            </Badge>
          ))
        ) : (
          <p style={{ fontSize: 13 }}>Without files.</p>
        )}
      </div>
    </div>
  )
}

export default FilesCompare
