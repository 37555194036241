import React from 'react'
import ContentHeader from '../../Components/ContentHeader'
import Layout from '../../Components/Layout'
import { useAuth } from '../../Contexts/AuthContext'
import BalancesHome from './components/BalancesHome'

const Home: React.FC = () => {
  const { user } = useAuth()
  const nameParts = (user?.name || '').split(' ')
  return (
    <Layout>
      <ContentHeader
        title={`Welcome ${nameParts[0]}!`}
        breadcrumbTitle='Home'
        showBreadcrumbHome={false}
      />
      <section className='content'>
        <BalancesHome />
      </section>
    </Layout>
  )
}
export default Home
