import React from 'react'

interface Props {
  active?: boolean
  disabled?: boolean
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
}

const PaginationItem: React.FC<Props> = ({
  children,
  active = false,
  disabled = false,
  onClick,
}) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault()
    if (onClick) onClick(e)
  }
  return (
    <li
      className={`page-item ${active ? 'active' : ''} ${
        disabled ? 'disabled' : ''
      }`}
    >
      {disabled ? (
        <span className='page-link'>{children}</span>
      ) : (
        <a className='page-link' href='#' onClick={handleClick} role='button'>
          {children}
        </a>
      )}
    </li>
  )
}

export default PaginationItem
