import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import Button from '../../Components/Button'

const NotFound: React.FC = () => {
  const history = useHistory()
  return (
    <section className='content d-flex vh-100 align-items-center'>
      <div className='error-page'>
        <h2 className='headline text-warning'> 404</h2>
        <div className='error-content p-3 p-md-0 text-center text-md-left'>
          <h3>
            <i className='fas fa-exclamation-triangle text-warning' /> Oops!
            Page not found.
          </h3>
          <p>
            We could not find the page you were looking for. Meanwhile, you may{' '}
            <Link to='/'>return to dashboard</Link>.
          </p>
          <Button color='primary' onClick={() => history.goBack()}>
            Back to previous page
          </Button>
        </div>
      </div>
    </section>
  )
}

export default NotFound
