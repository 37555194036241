import React, { useState } from 'react'
import { DateTime } from 'luxon'
import {
  Transaction,
  TransactionStatus,
  TransactionTypes,
} from '../../../../Interfaces/Transaction'
import transactionTypeReadable from '../../../../Utils/TransactionTypeReadable'
import formatCurrency from '../../../../Utils/FormatCurrency'
import transactionStatusReadable from '../../../../Utils/TransactionStatusReadable'
import Button from '../../../../Components/Button'
import Dropdown from '../../../../Components/Dropdown'
import TransactionDetail from '../TransactionDetail'
import useWithdrawComplete from '../../../../Services/WithdrawComplete'
import getGraphQLErrors from '../../../../Utils/GetGraphQLErrors'
import Alert from '../../../../Components/Alert'

interface Props {
  transactions: Transaction[]
}

const getIconClassname = (type: TransactionTypes): string => {
  switch (type) {
    case TransactionTypes.CREDITS_PURCHASE:
      return 'fas fa-money-bill-wave text-success'
    case TransactionTypes.PURCHASE:
      return 'fas fa-cart-plus text-info'
    case TransactionTypes.CHARGEBACK:
      return 'fas fa-cart-arrow-down text-info'
    case TransactionTypes.WITHDRAW:
      return 'fas fa-hand-holding-usd text-danger'
    case TransactionTypes.WITHDRAW_FEE:
      return 'fas fa-percentage text-info'
    case TransactionTypes.COLLECTION:
      return 'far fa-handshake text-info'
    case TransactionTypes.COLLECTION_CHARGEBACK:
      return 'far fa-thumbs-down text-info'
    case TransactionTypes.EXPENSE:
      return 'fas fa-file-invoice-dollar text-danger'
    case TransactionTypes.REVENUE:
      return 'fas fa-coins text-success'
    default:
      return 'far fa-circle'
  }
}

const getBadgeColorClass = (status: TransactionStatus): string => {
  switch (status) {
    case TransactionStatus.CANCELED:
      return 'badge-danger'
    case TransactionStatus.COMPLETED:
      return 'badge-success'
    default:
      return 'badge-warning'
  }
}

const TransactionsList: React.FC<Props> = ({ transactions }) => {
  const [openTransaction, setOpenTransaction] = useState<Transaction>()
  const openModal = (transaction: Transaction) => {
    setOpenTransaction(transaction)
  }
  const closeModal = () => setOpenTransaction(undefined)

  const [WithdrawComplete] = useWithdrawComplete()
  const [generalErrors, setGeneralErrors] = useState<string>()
  const [successMessage, setSuccessMessage] = useState<string>()

  const completeWithdraw = async (id: string): Promise<void> => {
    setGeneralErrors('')
    try {
      await WithdrawComplete({
        variables: {
          transactionId: id,
        },
        update: async cache => {
          await cache.reset()
        },
      }).then(() => setSuccessMessage('Withdrawal completed successfully'))
    } catch (err) {
      const defaultError = 'Failed. Try again.'
      const graphqlErrors = getGraphQLErrors(err, defaultError)
      const errorsArray = Object.values(graphqlErrors)
      setGeneralErrors(errorsArray[0]?.[0] || defaultError)
    }
  }
  const onDismissSuccess = () => {
    setSuccessMessage(undefined)
  }
  return (
    <>
      {successMessage && (
        <Alert color='success' isDismissible onDismiss={onDismissSuccess}>
          {successMessage}
        </Alert>
      )}
      {generalErrors && <Alert>{generalErrors}</Alert>}
      <table className='table table-striped table-hover projects'>
        <thead>
          <tr>
            <th style={{ width: '5%' }}>#</th>
            <th>Description</th>
            <th className='text-center'>Status</th>
            <th>Updated At</th>
            <th className='text-right'>Gross Value</th>
            <th className='text-right'>Fee</th>
            <th className='text-right'>Net Value</th>
            <th aria-label='Actions' style={{ width: 126 }} />
          </tr>
        </thead>
        <tbody>
          {transactions.length > 0 ? (
            transactions.map(transaction => (
              <tr key={transaction.id}>
                <td>
                  <i
                    title={transactionTypeReadable(transaction.type)}
                    className={getIconClassname(transaction.type)}
                  />
                </td>
                <td>
                  {transaction.description}
                  <br />
                  <small>
                    {transaction.associatedUser && (
                      <>
                        User <strong>{transaction.associatedUser.name}</strong>
                        {' - '}
                      </>
                    )}
                    Created{' '}
                    {DateTime.fromISO(transaction.createdAt)
                      .setLocale('en')
                      .toLocaleString(DateTime.DATETIME_MED)}
                  </small>
                  {transaction.type === 'WITHDRAW' && (
                    <>
                      <hr />
                      <small>
                        Type: <b>{transaction.withdrawDetails?.type}</b>
                        {transaction.withdrawDetails?.type === 'PIX' && (
                          <ul>
                            <li>
                              key: <b>{transaction.withdrawDetails.key}</b>
                            </li>
                            <li>
                              name:
                              <b>{transaction.withdrawDetails.pixName}</b>
                            </li>
                            <li>
                              institution:
                              <b>{transaction.withdrawDetails.institution}</b>
                            </li>
                          </ul>
                        )}
                        {transaction.withdrawDetails?.type === 'PAYPAL' && (
                          <p>
                            email: <b>{transaction.withdrawDetails.email}</b>
                          </p>
                        )}
                        {transaction.withdrawDetails?.type === 'TED' && (
                          <ul>
                            <li>
                              name: <b>{transaction.withdrawDetails.name}</b> -
                              cpf: <b>{transaction.withdrawDetails.cpf}</b>
                            </li>
                            <li>
                              bank:{' '}
                              <b>{transaction.withdrawDetails.bankName}</b> -
                              agency:{' '}
                              <b>{transaction.withdrawDetails.agency}</b> -
                              account:{' '}
                              <b>{transaction.withdrawDetails.account}</b>
                            </li>
                          </ul>
                        )}
                      </small>
                    </>
                  )}
                </td>
                <td className='project-state'>
                  <span
                    className={`badge ${getBadgeColorClass(
                      transaction.status
                    )}`}
                  >
                    {transactionStatusReadable(transaction.status)}
                  </span>
                </td>
                <td>
                  {DateTime.fromISO(transaction.updatedAt)
                    .setLocale('en')
                    .toLocaleString(DateTime.DATETIME_MED)}
                </td>
                <td className='text-right'>
                  {formatCurrency(transaction.value)}
                </td>
                <td className='text-right'>
                  {formatCurrency(transaction.gatewayFee || 0)}
                </td>
                <td className='text-right'>
                  <span
                    className={[
                      [
                        TransactionTypes.CREDITS_PURCHASE,
                        TransactionTypes.REVENUE,
                      ].includes(transaction.type)
                        ? 'text-success'
                        : '',
                      [
                        TransactionTypes.WITHDRAW,
                        TransactionTypes.EXPENSE,
                      ].includes(transaction.type)
                        ? 'text-danger'
                        : '',
                    ].join(' ')}
                  >
                    {formatCurrency(transaction.netValue)}
                  </span>
                </td>
                <td className='text-right'>
                  <Dropdown>
                    <Button
                      color='info'
                      className='dropdown-toggle'
                      data-toggle='dropdown'
                    >
                      Actions
                    </Button>
                    <Dropdown.Menu isMenuEnd>
                      <Dropdown.Item onClick={() => openModal(transaction)}>
                        Details
                      </Dropdown.Item>
                      {transaction.type === 'WITHDRAW' &&
                        transaction.status === 'CREATED' && (
                          <Dropdown.Item
                            onClick={() => completeWithdraw(transaction.id)}
                          >
                            Complete withdraw
                          </Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={8} className='text-center'>
                No transactions found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <TransactionDetail transaction={openTransaction} onClose={closeModal} />
    </>
  )
}

export default TransactionsList
