import { MutationTuple, gql, useMutation } from '@apollo/client'

const CHANGE_VISIBILITY = gql`
  mutation updateModelVisibility($modelId: ID!, $public: Boolean!) {
    updateModelVisibility(modelId: $modelId, public: $public)
  }
`

interface ModelVisibilityVars {
  modelId?: string
  public?: boolean
}

const useUpdateModelVisibility = (): MutationTuple<
  boolean,
  ModelVisibilityVars
> => {
  return useMutation(CHANGE_VISIBILITY)
}

export default useUpdateModelVisibility
