import React from 'react'

const Footer: React.FC = () => {
  return (
    <footer className='main-footer'>
      <strong>
        Copyright © {new Date().getFullYear()}{' '}
        <a target='_blank' href='https://co3d.art' rel='noreferrer'>
          CO3D
        </a>
        .
      </strong>{' '}
      All rights reserved.
    </footer>
  )
}
export default Footer
