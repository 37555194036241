import { FetchResult, gql } from '@apollo/client'
import { Session } from '../Interfaces/Session'
import client from './ApolloClient'

const DO_LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(
      email: $email
      password: $password
      remember: true
      loginType: ADMIN
    ) {
      accessToken
      expiresIn
      user {
        id
        name
      }
    }
  }
`

interface LoginResult {
  login: Session
}

const createSession = async (
  email: string,
  password: string
): Promise<FetchResult<LoginResult>> => {
  return client.mutate<LoginResult>({
    mutation: DO_LOGIN,
    variables: {
      email,
      password,
    },
  })
}

export default createSession
