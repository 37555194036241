import { gql, QueryResult, useQuery } from '@apollo/client'
import { Club } from '../Interfaces/Club'

const GET_CLUBS = gql`
  query clubs($includeNonPublic: Boolean, $id: ID) {
    clubs(includeNonPublic: $includeNonPublic, id: $id) {
      id
      name
      slug
      owner
      status
      description
      tags
      socialMedias {
        type
        link
      }
      sensitiveContent
      tiers {
        id
        title
        priceRaw
        price
        priceWithFee
        image {
          id
          photo
        }
        description
        benefits {
          label
        }
      }
      galleryDescription
      galleryImages {
        image {
          id
          photo
        }
        description
      }
      profileImage {
        id
        photo
      }
      coverImage {
        id
        photo
      }
    }
  }
`

interface GetClubData {
  clubs: Club[]
}
export interface GetClubVars {
  includeNonPublic: boolean
  id?: string
}

const useClubService = (variables: GetClubVars): QueryResult<GetClubData> => {
  return useQuery<GetClubData, GetClubVars>(GET_CLUBS, {
    variables,
    fetchPolicy: 'network-only',
  })
}

export default useClubService
