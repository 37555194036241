import React from 'react'

interface Props {
  className?: string
}

export type BodyComponent = React.FC<Props>

const Body: BodyComponent = ({ children, className }) => (
  <div className={`card-body ${className}`}>{children}</div>
)

export default Body
