import React from 'react'
import Option, { OptionComponent } from './components/Option'

interface Props {
  className?: string
  id?: string
  name?: string
  value?: string | number | string[]
  onChange?: React.ChangeEventHandler<HTMLSelectElement>
  onBlur?: React.FocusEventHandler<HTMLSelectElement>
  isInvalid?: boolean
  isWarning?: boolean
  disabled?: boolean
  multiple?: boolean
}

type SelectComponent = React.FC<Props> & {
  Option: OptionComponent
}

const Select: SelectComponent = ({
  className = '',
  id,
  name,
  value,
  onChange,
  onBlur,
  children,
  isInvalid = false,
  isWarning = false,
  disabled = false,
  multiple = false,
}) => {
  const isInvalidClass = isInvalid ? 'is-invalid' : ''
  const isWarningClass = isWarning ? 'is-warning' : ''
  return (
    <select
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      className={`form-control custom-select ${className} ${isInvalidClass} ${isWarningClass}`}
      name={name}
      id={id}
      disabled={disabled}
      multiple={multiple}
    >
      {children}
    </select>
  )
}
Select.Option = Option

export default Select
