import React, { useState } from 'react'
import { DateTime } from 'luxon'
import Alert from '../../Components/Alert'
import Card from '../../Components/Card'
import ContentHeader from '../../Components/ContentHeader'
import Layout from '../../Components/Layout'
import Loading from '../../Components/Loading'
import PaginationFooter from '../../Components/PaginationFooter'
import { PaginationInfo } from '../../Interfaces/PaginationInfo'
import { OrderDirection, IWhereObject } from '../../Interfaces/SearchableFilter'
import { Transaction } from '../../Interfaces/Transaction'
import useTransactionsSearchable from '../../Services/TransactionsSearchable'
import TransactionsFilter, {
  FilterFormFields,
} from './components/TransactionsFilter'
import TransactionsList from './components/TransactionsList'
import TransactionTotalizer from './components/TransactionTotalizer'

const initialValues: FilterFormFields = {
  direction: OrderDirection.ASC,
  orderBy: 'updatedAt',
  period: {
    start: DateTime.now().startOf('month'),
    end: DateTime.now().endOf('month'),
  },
  status: ['COMPLETED', 'CREATED'],
  types: ['CREDITS_PURCHASE', 'WITHDRAW', 'EXPENSE', 'REVENUE'],
}

interface Filter {
  skip: number
  limit: number
  direction: OrderDirection
  orderBy: keyof Transaction
  where: IWhereObject
}

const periodToISO = (date: DateTime, position: 'start' | 'end'): string => {
  const time = position === 'start' ? '00:00:00.000' : '23:59:59.999'
  return `${date.toFormat('yyyy-MM-dd')}T${time}Z`
}

const getFilter = (values: FilterFormFields, limit = 20): Filter => {
  const where: IWhereObject = {}
  where.updatedAt = {
    gte: { toDate: periodToISO(values.period.start, 'start') },
    lte: { toDate: periodToISO(values.period.end, 'end') },
  }
  const validStatus = values.status.filter(status => status !== '')
  if (validStatus.length > 0) {
    where.status = {
      in: validStatus,
    }
  }
  const validTypes = values.types.filter(type => type !== '')
  if (validTypes.length > 0) {
    where.type = {
      in: validTypes,
    }
  }
  return {
    skip: 0,
    limit,
    direction: values.direction,
    orderBy: values.orderBy,
    where,
  }
}

const Transactions: React.FC = () => {
  const [filterFields, setFilterFields] = useState<FilterFormFields>(
    initialValues
  )
  const [filter, setFilter] = useState<Filter>(getFilter(initialValues))
  const { data, loading, error } = useTransactionsSearchable({
    filter: {
      skip: filter.skip,
      limit: filter.limit,
      direction: filter.direction,
      orderBy: filter.orderBy,
      where: filter.where,
    },
  })

  const onChangePage = ({ skip, limit }: PaginationInfo) => {
    setFilter({
      ...filter,
      skip,
      limit,
    })
  }

  const onChangeLimit = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const limit = parseInt(e.target.value, 10)
    setFilter({
      ...filter,
      skip: 0,
      limit,
    })
  }

  const onUpdateFilter = (newFields: FilterFormFields) => {
    setFilterFields(newFields)
    setFilter(getFilter(newFields, filter.limit))
  }

  const transactions = data?.transactionsSearchable.items || []
  const total = data?.transactionsSearchable.totalCount || 0
  const isLastPage = total <= filter.skip + filter.limit
  return (
    <Layout>
      <ContentHeader title='Transactions' />
      <section className='content'>
        <TransactionsFilter
          initialValues={initialValues}
          onUpdateFilter={onUpdateFilter}
        />
        {loading ? (
          <Loading />
        ) : (
          <>
            {error ? (
              <Alert>An error occurred while querying the data.</Alert>
            ) : (
              <Card>
                <Card.Body className='p-0'>
                  <TransactionsList transactions={transactions} />
                  {isLastPage && (
                    <TransactionTotalizer
                      startDate={periodToISO(
                        filterFields.period.start,
                        'start'
                      )}
                      endDate={periodToISO(filterFields.period.end, 'end')}
                    />
                  )}
                </Card.Body>
                <Card.Footer>
                  <PaginationFooter
                    limit={filter.limit}
                    skip={filter.skip}
                    total={total}
                    totalShowing={transactions.length}
                    onChangeLimit={onChangeLimit}
                    onChangePage={onChangePage}
                  />
                </Card.Footer>
              </Card>
            )}
          </>
        )}
      </section>
    </Layout>
  )
}
export default Transactions
