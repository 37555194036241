import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const Menu: React.FC = () => {
  useEffect(() => {
    $('[data-widget="treeview"]').each(function initTreeview() {
      // eslint-disable-next-line no-underscore-dangle
      adminlte.Treeview._jQueryInterface.call($(this), 'init')
    })
  }, [])
  return (
    <aside className='main-sidebar sidebar-dark-primary elevation-4'>
      <Link to='/' className='brand-link'>
        <span
          className='brand-image img-circle elevation-3 d-flex p-1 bg-white'
          style={{ height: 33 }}
        >
          <img
            src='/dist/img/co-3d-logo.svg'
            alt='CO3D Nema Admin'
            style={{ maxWidth: 25, height: 'auto' }}
          />
        </span>
        <span className='brand-text font-weight-light'>Nema Admin</span>
      </Link>
      <div className='sidebar'>
        <nav className='mt-2'>
          <ul
            className='nav nav-pills nav-sidebar flex-column'
            data-widget='treeview'
            role='menu'
            data-accordion='false'
          >
            <li className='nav-header'>Main Menu</li>
            <li className='nav-item has-treeview'>
              <a href='#' className='nav-link'>
                <i className='nav-icon fas fa-cubes' />
                <p>
                  Projects
                  <i className='fas fa-angle-left right' />
                </p>
              </a>
              <ul className='nav nav-treeview'>
                <li className='nav-item'>
                  <Link to='/projects' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>List</p>
                  </Link>
                </li>
              </ul>
            </li>
            <li className='nav-item has-treeview'>
              <a href='#' className='nav-link'>
                <i className='nav-icon fas fa-coins' />
                <p>
                  Transactions
                  <i className='fas fa-angle-left right' />
                </p>
              </a>
              <ul className='nav nav-treeview'>
                <li className='nav-item'>
                  <Link to='/transactions' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>List</p>
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/insert-transaction' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>Insert</p>
                  </Link>
                </li>
              </ul>
            </li>
            <li className='nav-item has-treeview'>
              <a href='#' className='nav-link'>
                <i className='nav-icon fas fa-trophy' />
                <p>
                  Contests
                  <i className='fas fa-angle-left right' />
                </p>
              </a>
              <ul className='nav nav-treeview'>
                <li className='nav-item'>
                  <Link to='/contests/tokusatsu' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>Tokusatsu</p>
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/contests/printandpaint' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>Print and Paint</p>
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/contests/hype2022part1' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>Hype 2022 p1</p>
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/contests/hype2022part2' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>Hype 2022 p2</p>
                  </Link>
                </li>
              </ul>
            </li>
            <li className='nav-item has-treeview'>
              <a href='#' className='nav-link'>
                <i className='nav-icon fas fa-campground' />
                <p>
                  Clubs
                  <i className='fas fa-angle-left right' />
                </p>
              </a>
              <ul className='nav nav-treeview'>
                <li className='nav-item'>
                  <Link to='/clubs' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>List</p>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  )
}
export default Menu
