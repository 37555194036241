import { useFormik } from 'formik'
import React from 'react'
import Card from '../../../../Components/Card'
import {
  IWhereObject,
  OrderDirection,
  SearchableFilter,
} from '../../../../Interfaces/SearchableFilter'
import getFormikFieldProps from '../../../../Utils/GetFormikFieldProps'
import FormField from '../../../../Components/FormField'
import Button from '../../../../Components/Button'
import Select from '../../../../Components/Select'
import { Model, ModelStatus } from '../../../../Interfaces/Model'
import statusReadable from '../../../../Utils/StatusReadable'

export interface ModelSearchableFilter extends SearchableFilter<Model> {
  hasPendingVersion?: boolean
}

interface Props {
  defaultDirection: OrderDirection
  defaultOrderBy: keyof Model
  onUpdateFilter: (filters: ModelSearchableFilter) => void
}

interface FormFields {
  direction: OrderDirection
  orderBy: keyof Model
  name: string
  slug: string
  status: string
  public: string
  hasPendingVersion: string
}

const ModelsFilter: React.FC<Props> = ({
  defaultDirection,
  defaultOrderBy,
  onUpdateFilter,
}) => {
  const updateFilter = (values: FormFields) => {
    const where: IWhereObject = {}
    if (values.name.trim() !== '') {
      where.name = { regex: values.name, options: 'i' }
    }
    if (values.slug.trim() !== '') {
      where.slug = { regex: values.slug, options: 'i' }
    }
    if (values.status.trim() !== '') {
      where.status = values.status
    }
    if (values.public.trim() !== '') {
      where.public = values.public === 'true'
    }
    const hasPendingVersion =
      values.hasPendingVersion != null && values.hasPendingVersion !== ''
        ? values.hasPendingVersion === 'true'
        : undefined
    onUpdateFilter({
      orderBy: values.orderBy,
      direction: values.direction,
      where,
      hasPendingVersion,
    })
  }
  const initialValues = {
    direction: defaultDirection,
    orderBy: defaultOrderBy,
    name: '',
    slug: '',
    status: '',
    public: '',
    hasPendingVersion: '',
  }
  const formik = useFormik<FormFields>({
    initialValues,
    onSubmit: async values => {
      updateFilter(values)
    },
  })
  const resetForm = () => {
    formik.resetForm()
    updateFilter(initialValues)
  }
  return (
    <Card collapsed>
      <Card.Header title='Filter'>
        <button
          type='button'
          className='btn btn-tool'
          data-card-widget='collapse'
          data-toggle='tooltip'
          title='Collapse'
        >
          <i className='fas fa-plus' />
        </button>
      </Card.Header>
      <Card.Body>
        <form noValidate onSubmit={formik.handleSubmit}>
          <div className='row'>
            <FormField
              {...getFormikFieldProps(formik, 'name')}
              label='Name:'
              className='col'
            />
            <FormField
              {...getFormikFieldProps(formik, 'slug')}
              label='Slug:'
              className='col'
            />
            <FormField
              {...getFormikFieldProps(formik, 'status')}
              select
              label='Status:'
              className='col'
            >
              <Select.Option value=''>All</Select.Option>
              {Object.values(ModelStatus).map(status => (
                <Select.Option value={status} key={status}>
                  {statusReadable(status)}
                </Select.Option>
              ))}
            </FormField>
            <FormField
              {...getFormikFieldProps(formik, 'public')}
              select
              label='Visibility:'
              className='col'
            >
              <Select.Option value=''>All</Select.Option>
              <Select.Option value='true'>Visible</Select.Option>
              <Select.Option value='false'>Hidden</Select.Option>
            </FormField>
          </div>
          <div className='row'>
            <FormField
              {...getFormikFieldProps(formik, 'hasPendingVersion')}
              select
              label='Pending version:'
              className='col'
            >
              <Select.Option value=''>All</Select.Option>
              <Select.Option value='true'>Has pending version</Select.Option>
              <Select.Option value='false'>
                Not has pending version
              </Select.Option>
            </FormField>
            <FormField
              {...getFormikFieldProps(formik, 'orderBy')}
              select
              label='Order by:'
              className='col'
            >
              <Select.Option value='createdAt'>Creation date</Select.Option>
              <Select.Option value='updatedAt'>Update date</Select.Option>
              <Select.Option value='name'>Name</Select.Option>
            </FormField>
            <FormField
              {...getFormikFieldProps(formik, 'direction')}
              select
              label='Order direction:'
              className='col'
            >
              <Select.Option value='ASC'>Ascending</Select.Option>
              <Select.Option value='DESC'>Descending</Select.Option>
            </FormField>
          </div>
          <Button
            color='primary'
            disabled={formik.isValidating || formik.isSubmitting}
            loading={formik.isValidating || formik.isSubmitting}
            className='mr-2'
          >
            Search
          </Button>
          <Button
            color='secondary'
            disabled={formik.isValidating || formik.isSubmitting}
            loading={formik.isValidating || formik.isSubmitting}
            onClick={resetForm}
            type='button'
          >
            Reset
          </Button>
        </form>
      </Card.Body>
    </Card>
  )
}

export default ModelsFilter
