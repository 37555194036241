import React, { useState } from 'react'
import { DateTime } from 'luxon'
import Alert from '../../Components/Alert'
import Card from '../../Components/Card'
import ContentHeader from '../../Components/ContentHeader'
import Layout from '../../Components/Layout'
import Loading from '../../Components/Loading'
import useNotificationsService, {
  GetNotificationsVars,
} from '../../Services/NotificationsService'
import useNotificationsReaded from '../../Services/NotificationsReaded'
import PaginationFooter from '../../Components/PaginationFooter'
import { PaginationInfo } from '../../Interfaces/PaginationInfo'
import Button from '../../Components/Button'
import getGraphQLErrors from '../../Utils/GetGraphQLErrors'

const initialFilter: GetNotificationsVars = {
  skip: 0,
  limit: 20,
}

const Notifications: React.FC = () => {
  const [filter, setFilter] = useState(initialFilter)
  const [notificationsReaded] = useNotificationsReaded()
  const [generalErrors, setGeneralErrors] = useState<string>()

  const { data, loading, error } = useNotificationsService({
    skip: filter.skip,
    limit: filter.limit,
  })
  const onChangePage = ({ skip, limit }: PaginationInfo) => {
    setFilter({
      ...filter,
      skip,
      limit,
    })
  }

  const onChangeLimit = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const limit = parseInt(e.target.value, 10)
    setFilter({
      ...filter,
      skip: 0,
      limit,
    })
  }

  const handleNotificationClick = async (id: string): Promise<void> => {
    setGeneralErrors('')

    try {
      await notificationsReaded({
        variables: {
          notificationId: id,
        },
        update: async cache => {
          await cache.reset()
        },
      })
    } catch (err) {
      const defaultError = 'Failed. Try again.'
      const graphqlErrors = getGraphQLErrors(err, defaultError)
      const errorsArray = Object.values(graphqlErrors)
      setGeneralErrors(errorsArray[0]?.[0] || defaultError)
    }
  }
  const notifications = data?.notifications.notifications || []
  const total = data?.notifications.totalCount || 0

  return (
    <Layout>
      <ContentHeader title='Notifications' />

      <section className='content'>
        {loading ? (
          <Loading />
        ) : (
          <>
            {generalErrors && <Alert>{generalErrors}</Alert>}

            {error ? (
              <Alert>An error occurred while querying the data.</Alert>
            ) : (
              <Card>
                <Card.Body className='p-0'>
                  <table className='table table-striped table-hover projects'>
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th> </th>
                      </tr>
                    </thead>
                    <tbody>
                      {notifications.length > 0 ? (
                        notifications.map(notification => (
                          <tr key={notification.id}>
                            <td
                              className={
                                notification.readedAt === null
                                  ? 'font-weight-bold'
                                  : ''
                              }
                            >
                              {notification.title}
                              <br />
                              <small>
                                {DateTime.fromISO(notification.createdAt)
                                  .setLocale('en')
                                  .toLocaleString(DateTime.DATETIME_MED)}
                              </small>
                            </td>
                            <td className='text-right'>
                              {notification.readedAt === null && (
                                <Button
                                  color='info'
                                  onClick={() =>
                                    handleNotificationClick(notification.id)
                                  }
                                >
                                  Read
                                </Button>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={2} className='text-center'>
                            No Notifications
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </Card.Body>
                <Card.Footer>
                  <PaginationFooter
                    limit={filter.limit}
                    skip={filter.skip}
                    total={total}
                    totalShowing={notifications.length}
                    onChangeLimit={onChangeLimit}
                    onChangePage={onChangePage}
                  />
                </Card.Footer>
              </Card>
            )}
          </>
        )}
      </section>
    </Layout>
  )
}
export default Notifications
