import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Alert from '../../Components/Alert'
import Card from '../../Components/Card'
import ContentHeader from '../../Components/ContentHeader'
import Layout from '../../Components/Layout'
import Loading from '../../Components/Loading'
import useClubsService from '../../Services/ClubsService'
import ClubList from './Components/ClubList'

interface ClubsState {
  successMessage?: string
}

const Club: React.FC = () => {
  const { data, loading, error } = useClubsService({
    includeNonPublic: true,
  })
  const history = useHistory()
  const location = useLocation<ClubsState>()
  const [successMessage, setSuccessMessage] = useState<string>()

  useEffect(() => {
    history.replace(location.pathname)
    const message = location.state?.successMessage
    if (message) {
      setSuccessMessage(message)
    }
  }, [history, location.pathname, location.state])
  const onDismissSuccess = () => {
    setSuccessMessage(undefined)
  }

  const clubs = data?.clubs || []
  return (
    <Layout>
      <ContentHeader title='Clubs' />

      <section className='content'>
        {successMessage && (
          <Alert color='success' isDismissible onDismiss={onDismissSuccess}>
            {successMessage}
          </Alert>
        )}

        {loading ? (
          <Loading />
        ) : (
          <>
            {error ? (
              <Alert>An error occurred while querying the data.</Alert>
            ) : (
              <Card>
                <Card.Body className='p-0'>
                  <ClubList clubs={clubs} />
                </Card.Body>
              </Card>
            )}
          </>
        )}
      </section>
    </Layout>
  )
}
export default Club
