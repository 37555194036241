import {
  FormikContextType,
  FieldInputProps as FieldInputPropsFormik,
} from 'formik'

interface FieldInputProps
  extends FieldInputPropsFormik<string | number | undefined> {
  errorMessage?: string
}

const getFormikFieldProps = <T>(
  formik: FormikContextType<T>,
  fieldName: keyof T
): FieldInputProps => {
  const errorMessage =
    formik.touched[fieldName] && formik.errors[fieldName]
      ? formik.errors[fieldName]
      : undefined
  return {
    ...formik.getFieldProps(fieldName),
    errorMessage: errorMessage?.toString(),
  }
}

export default getFormikFieldProps
