import { ModelStatus } from '../Interfaces/Model'
import { ClubStatus } from '../Interfaces/Club'

const statusReadable = (status: ModelStatus | ClubStatus): string => {
  return {
    REQUESTED: 'Requested',
    RAISING_FUNDS: 'Raising Funds',
    MODELING: 'Modeling',
    FINISHED: 'Finished',
    CANCELED: 'Canceled',
    CREATED: 'Created',
    AWAITING_REVIEW: 'Awaiting review',
    PUBLISHED: 'Published',
  }[status]
}

export default statusReadable
