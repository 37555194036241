import React from 'react'
import Input, { Props as InputProps } from '../Input'
import InputGroup, { Props as InputGroupProps } from '../InputGroup'
import Select from '../Select'

export interface FormFieldProps
  extends InputGroupProps,
    Omit<InputProps, 'onChange' | 'onBlur'> {
  className?: string
  inputClassName?: string
  label?: string
  errorMessage?: string
  helperText?: string
  select?: boolean
  disabled?: boolean
  isWarning?: boolean
  onBlur?: (
    e: React.FocusEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => void
  onChange?: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => void
}

const FormField: React.FC<FormFieldProps> = ({
  checked,
  className,
  id,
  inputClassName,
  label,
  multiple,
  name,
  onBlur,
  onChange,
  placeholder,
  type,
  value,
  append,
  appendIsText,
  prepend,
  prependIsText,
  errorMessage,
  helperText,
  select = false,
  children,
  disabled = false,
  isWarning,
}) => {
  if (process.env.NODE_ENV !== 'production') {
    if (select && !children) {
      // eslint-disable-next-line no-console
      console.error(
        'FormField: `children` must be passed when using the `FormField` component with `select`.'
      )
    }
  }

  const inputId = id || name || undefined
  const inputElement = select ? (
    <Select
      className={inputClassName}
      id={id}
      name={name}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      isInvalid={!!errorMessage}
      isWarning={isWarning}
      disabled={disabled}
      multiple={multiple}
    >
      {children}
    </Select>
  ) : (
    <Input
      checked={checked}
      className={inputClassName}
      id={inputId}
      multiple={multiple}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      placeholder={placeholder}
      type={type}
      value={value}
      isInvalid={!!errorMessage}
      isWarning={isWarning}
      disabled={disabled}
    />
  )
  return (
    <div className={`form-group ${className}`}>
      {label && <label htmlFor={inputId}>{label}</label>}
      {append || prepend ? (
        <InputGroup
          append={append}
          appendIsText={appendIsText}
          prepend={prepend}
          prependIsText={prependIsText}
        >
          {inputElement}
        </InputGroup>
      ) : (
        inputElement
      )}
      {errorMessage && (
        <span className='invalid-feedback d-block'>{errorMessage}</span>
      )}
      {helperText && (
        <small className='form-text text-muted'>{helperText}</small>
      )}
    </div>
  )
}

export default FormField
