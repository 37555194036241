export enum OrderDirection {
  'ASC' = 'ASC',
  'DESC' = 'DESC',
}

export interface IWhereObject {
  [key: string]:
    | string
    | string[]
    | number
    | number[]
    | boolean
    | IWhereObject
    | IWhereObject[]
}

export interface SearchableFilter<T> {
  skip?: number
  limit?: number
  direction?: OrderDirection
  where?: IWhereObject
  orderBy?: keyof T
}
