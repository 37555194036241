import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { useAuth } from '../../Contexts/AuthContext'
import Loading from '../Loading'

const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const { loadingUser, user } = useAuth()
  if (loadingUser) {
    return <Loading />
  }
  return (
    <Route
      {...rest}
      render={({ location }) =>
        user != null ? (
          children
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: location.pathname } }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
