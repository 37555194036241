import React from 'react'
import { Photo } from '../../Interfaces/Photo'

export interface GalleryItem extends Photo {
  className?: string
}

interface Props {
  photos: GalleryItem[]
  className?: string
  itemWidth?: number
}

const Gallery: React.FC<Props> = ({ photos, className, itemWidth }) => (
  <div className={`row ${className || ''}`}>
    {photos.map(photo => (
      <div
        className='col mb-3'
        key={photo.id}
        style={{ flex: itemWidth ? `0 0 ${itemWidth}px` : undefined }}
      >
        <img
          src={photo.photo}
          alt='Model'
          className={`img-fluid ${photo.className}`}
        />
      </div>
    ))}
  </div>
)

export default Gallery
