import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import PrivateRoute from './Components/PrivateRoute'

import Home from './Pages/Home'
import Notifications from './Pages/Notifications'
import Login from './Pages/Login'
import ModelVersionPending from './Pages/ModelVersionPending'
import NotFound from './Pages/NotFound'
import Projects from './Pages/Projects'
import Transactions from './Pages/Transactions'
import InsertTransaction from './Pages/InsertTransaction'
import Club from './Pages/Club'
import Contests from './Pages/Contests'
import ClubDetail from './Pages/ClubDetail'

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <PrivateRoute path='/' exact>
          <Home />
        </PrivateRoute>
        <PrivateRoute path='/notifications' exact>
          <Notifications />
        </PrivateRoute>
        <PrivateRoute path='/projects' exact>
          <Projects />
        </PrivateRoute>
        <PrivateRoute path='/model-version-pending/:modelId'>
          <ModelVersionPending />
        </PrivateRoute>
        <PrivateRoute path='/transactions' exact>
          <Transactions />
        </PrivateRoute>
        <PrivateRoute path='/insert-transaction' exact>
          <InsertTransaction />
        </PrivateRoute>

        <PrivateRoute path='/contests/:slug'>
          <Contests />
        </PrivateRoute>
        <PrivateRoute path='/clubs' exact>
          <Club />
        </PrivateRoute>
        <PrivateRoute path='/clubs/:clubId'>
          <ClubDetail />
        </PrivateRoute>
        <Route path='/login' component={Login} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  )
}
export default Routes
