import { ApolloError, gql, useQuery } from '@apollo/client'
import { FinancialResults } from '../Interfaces/FinancialResults'

const GET_BALANCES = gql`
  query balances {
    earnings: transactionsSearchable(
      filter: {
        where: {
          type: { in: ["CREDITS_PURCHASE", "REVENUE"] }
          status: "COMPLETED"
        }
      }
    ) {
      ...aggregationSum
    }
    expenses: transactionsSearchable(
      filter: {
        where: { type: { in: ["WITHDRAW", "EXPENSE"] }, status: "COMPLETED" }
      }
    ) {
      ...aggregationSum
    }
    pendingCollections: transactionsSearchable(
      filter: { where: { type: "COLLECTION", status: "CREATED" } }
    ) {
      aggregate {
        sum {
          modelPurchaseWithFee
        }
      }
    }
    usersBalance: usersSearchable(
      filter: {
        where: {
          or: [
            { balanceChargeback: { gt: 0 } }
            { balanceCollection: { gt: 0 } }
          ]
        }
      }
    ) {
      aggregate {
        sum {
          balance
        }
      }
    }
  }
  fragment aggregationSum on TransactionsSearchable {
    aggregate {
      sum {
        netValue
      }
    }
  }
`

interface TransactionAggregationSum {
  aggregate: {
    sum: {
      netValue: number
    }
  }
}

interface PendingCollectionAggregationSum {
  aggregate: {
    sum: {
      modelPurchaseWithFee: number
    }
  }
}

interface UserAggregationSum {
  aggregate: {
    sum: {
      balance: number
    }
  }
}

interface GetBalancesData {
  earnings: TransactionAggregationSum
  expenses: TransactionAggregationSum
  pendingCollections: PendingCollectionAggregationSum
  usersBalance: UserAggregationSum
}

interface FinancialResultsResult {
  loading: boolean
  data?: FinancialResults
  error?: ApolloError
}

const getData = (data: GetBalancesData) => {
  const earnings = data.earnings.aggregate.sum.netValue
  const expenses = data.expenses.aggregate.sum.netValue
  const balance = earnings - expenses
  const pendingCollections =
    data.pendingCollections.aggregate.sum.modelPurchaseWithFee
  const usersBalance = data.usersBalance.aggregate.sum.balance
  const committedBalance = pendingCollections + usersBalance
  const availableBalance = balance - committedBalance
  return {
    earnings,
    expenses,
    balance,
    committedBalance,
    availableBalance,
  }
}

const useFinancialResults = (): FinancialResultsResult => {
  const { data, loading, error } = useQuery<GetBalancesData>(GET_BALANCES)
  return {
    loading,
    data: data && !error ? getData(data) : undefined,
    error,
  }
}

export default useFinancialResults
