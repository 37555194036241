import { gql, QueryResult, useQuery } from '@apollo/client'
import { Contest } from '../Interfaces/Contest'

const GET_CONTESTS = gql`
  query contests(
    $slug: String!
    $returnNonPublic: Boolean
    $skipEntries: Int
    $limitEntries: Int
  ) {
    contests(
      slug: $slug
      returnNonPublic: $returnNonPublic
      skipEntries: $skipEntries
      limitEntries: $limitEntries
    ) {
      id
      countEntries
      entries {
        id
        public
        authorIsBacker
        title
        author {
          id
          name
        }
        featuredPhoto {
          id
          photo
        }
        associatedModel {
          id
          name
          slug
        }
      }
    }
  }
`

interface GetContestData {
  contests: Contest
}

export interface GetContestVars {
  slug: string
  skipEntries: number
  limitEntries: number
  returnNonPublic: boolean
}

const useContestsService = (
  variables: GetContestVars
): QueryResult<GetContestData> => {
  return useQuery<GetContestData, GetContestVars>(GET_CONTESTS, {
    variables,
    fetchPolicy: 'network-only',
  })
}

export default useContestsService
