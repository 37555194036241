import { MutationTuple, gql, useMutation } from '@apollo/client'

const CLUB_APPROVE = gql`
  mutation approveClub($clubId: ID!) {
    approveClub(clubId: $clubId)
  }
`

interface ClubVars {
  clubId: string
}

const useClubApproval = (): MutationTuple<boolean, ClubVars> => {
  return useMutation(CLUB_APPROVE)
}

export default useClubApproval
