import React from 'react'

interface Props {
  showCloseButton?: boolean
  onClose?: () => void
  title?: string
}

export type HeaderComponent = React.FC<Props>

const Header: HeaderComponent = ({
  showCloseButton = true,
  onClose,
  title,
}) => (
  <div className='modal-header'>
    {title && <h5 className='modal-title'>{title}</h5>}
    {showCloseButton && (
      <button
        type='button'
        className='close'
        data-dismiss='modal'
        aria-label='Close'
        onClick={onClose}
      >
        <span aria-hidden='true'>&times;</span>
      </button>
    )}
  </div>
)

export default Header
