import { MutationTuple, gql, useMutation } from '@apollo/client'

const CHANGE_VISIBILITY = gql`
  mutation changeContestEntry(
    $contestId: ID!
    $entryId: ID!
    $entry: ContestEditEntry!
  ) {
    changeContestEntry(contestId: $contestId, entryId: $entryId, entry: $entry)
  }
`

interface ContestVars {
  contestId: string
  entryId: string
  entry: ContestEditEntry
}

interface ContestEditEntry {
  public: boolean
}

const useContestChangeVisibility = (): MutationTuple<boolean, ContestVars> => {
  return useMutation(CHANGE_VISIBILITY)
}

export default useContestChangeVisibility
