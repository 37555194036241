import React from 'react'
import Button, { ButtonProps } from '../Button'
import ModalBase, { ModalBaseProps } from '../ModalBase'

interface ButtonModalProps extends ButtonProps {
  render?: () => React.ReactNode
  label?: string
}

interface Props extends ModalBaseProps {
  title?: string
  buttons?: ButtonModalProps[]
}

const Modal: React.FC<Props> = ({
  show,
  onClose,
  isStaticBackdrop,
  buttons,
  title,
  children,
}) => {
  return (
    <ModalBase
      show={show}
      onClose={onClose}
      isStaticBackdrop={isStaticBackdrop}
    >
      <ModalBase.Header title={title} onClose={onClose} />
      <ModalBase.Body>{children}</ModalBase.Body>
      {buttons && buttons.length > 0 && (
        <ModalBase.Footer>
          {buttons.map(({ render, label, ...buttonProps }, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={i}>
              {render ? render() : <Button {...buttonProps}>{label}</Button>}
            </React.Fragment>
          ))}
        </ModalBase.Footer>
      )}
    </ModalBase>
  )
}

export default Modal
