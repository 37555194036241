import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Alert from '../../../../Components/Alert'
import Button from '../../../../Components/Button'
import Card from '../../../../Components/Card'
import FormField from '../../../../Components/FormField'
import { useSettings } from '../../../../Contexts/SettingsContext'
import { Model, ModelStatus } from '../../../../Interfaces/Model'
import {
  ModelVersion,
  ModelVersionAnswers,
} from '../../../../Interfaces/ModelVersion'
import useAnswerModelVersion from '../../../../Services/AnswerModelVersion'
import formatCurrency from '../../../../Utils/FormatCurrency'
import getCategoryName from '../../../../Utils/GetCategoryName'
import getGraphQLErrors from '../../../../Utils/GetGraphQLErrors'
import getQuotaValue, { QuotaValuesKeys } from '../../../../Utils/GetQuotaValue'
import FilesCompare from '../FilesCompare'
import GalleryCompare from '../GalleryCompare'
import ModalConfirm from '../ModalConfirm'
import VideoCompare from '../VideoCompare'

interface Props {
  model: Model
  modelVersion: ModelVersion
}

const VersionForm: React.FC<Props> = ({ model, modelVersion }) => {
  const [answerModelVersion, { loading }] = useAnswerModelVersion()
  const history = useHistory()
  const [showModal, setShowModal] = useState(false)
  const [generalErrors, setGeneralErrors] = useState<string>()
  const [answer, setAnswer] = useState<ModelVersionAnswers>()
  const { settings } = useSettings()
  const openModal = (newAnswer: ModelVersionAnswers) => {
    setAnswer(newAnswer)
    setShowModal(true)
  }
  const closeModal = () => {
    setAnswer(undefined)
    setShowModal(false)
  }
  const onConfirmModal = async (): Promise<void> => {
    try {
      setGeneralErrors('')
      if (!answer) throw new Error('No answer')
      await answerModelVersion({
        variables: {
          modelId: model.id,
          modelVersionId: modelVersion.id,
          versionCount: modelVersion.versionCount,
          answer,
        },
        update: async cache => {
          await cache.reset()
        },
      })
      history.push('/projects', {
        successMessage:
          answer === ModelVersionAnswers.ACCEPT
            ? 'Model updated successfully.'
            : 'Model version successfully refused.',
      })
    } catch (error) {
      if (error.message === 'No answer') {
        setGeneralErrors('Answer not selected. Try again.')
      } else {
        const defaultError = 'Failed. Try again.'
        const graphqlErrors = getGraphQLErrors(error, defaultError)
        const errorsArray = Object.values(graphqlErrors)
        setGeneralErrors(errorsArray[0]?.[0] || defaultError)
      }
      closeModal()
    }
  }
  const allTagsMaintain =
    model.tags == null
      ? true
      : model.tags.reduce((result, tag) => {
          if (
            modelVersion.info.tags == null ||
            !modelVersion.info.tags.includes(tag)
          ) {
            return false
          }
          return result
        }, true)
  const oldTagsLength = model.tags != null ? model.tags.length : 0
  const newTagsLength =
    modelVersion.info.tags != null ? modelVersion.info.tags.length : 0
  const quotaPrice =
    modelVersion.info.amount && modelVersion.info.totalQuotas && settings
      ? getQuotaValue(
          modelVersion.info.amount,
          modelVersion.info.totalQuotas,
          settings
        )
      : undefined
  const quotaPriceOld =
    model.amount && model.totalQuotas && settings
      ? getQuotaValue(model.amount, model.totalQuotas, settings)
      : undefined
  let quotaPriceKey: QuotaValuesKeys = 'withoutFee'
  if (model.skipRaisingFunds) {
    quotaPriceKey = 'finishedMarketplace'
  } else if (
    model.status === ModelStatus.RAISING_FUNDS ||
    model.status === ModelStatus.MODELING ||
    model.status === ModelStatus.FINISHED
  ) {
    quotaPriceKey = model.status
  }
  return (
    <>
      {generalErrors && <Alert>{generalErrors}</Alert>}
      <Card>
        <Card.Header title='Basic data' />
        <Card.Body>
          <div className='row'>
            <FormField
              label='Name'
              value={modelVersion.info.name}
              disabled
              helperText={`Original: ${model.name}`}
              className='col'
              isWarning={modelVersion.info.name !== model.name}
            />
            <FormField
              label='Slug'
              value={modelVersion.info.slug}
              disabled
              helperText={`Original: ${model.slug}`}
              className='col'
              isWarning={modelVersion.info.slug !== model.slug}
            />
            <FormField
              label='Category'
              value={`${getCategoryName(modelVersion.info.category)} (${
                modelVersion.info.category.id
              })`}
              disabled
              helperText={`Original: ${getCategoryName(model.category)} (${
                model.category.id
              })`}
              className='col'
              isWarning={modelVersion.info.category.id !== model.category.id}
            />
          </div>
          <div className='row'>
            <FormField
              label='Scale'
              value={modelVersion.info.scale}
              disabled
              helperText={`Original: ${model.scale}`}
              className='col'
              isWarning={modelVersion.info.scale !== model.scale}
            />
            <FormField
              label='Sensitive content'
              value={modelVersion.info.sensitiveContent ? 'Yes' : 'No'}
              disabled
              helperText={`Original: ${model.sensitiveContent ? 'Yes' : 'No'}`}
              className='col'
              isWarning={
                modelVersion.info.sensitiveContent !== model.sensitiveContent
              }
            />
          </div>
          <div className='row'>
            {modelVersion.info.description && (
              <FormField
                label='Description'
                value={modelVersion.info.description}
                disabled
                helperText={`Original: ${model.description}`}
                className='col'
                isWarning={modelVersion.info.description !== model.description}
                multiple
              />
            )}
            <FormField
              label='Tags'
              value={
                modelVersion.info.tags && modelVersion.info.tags.length > 0
                  ? modelVersion.info.tags.join(', ')
                  : 'Without tags.'
              }
              disabled
              helperText={`Original: ${
                model.tags && model.tags.length > 0
                  ? model.tags?.join(', ')
                  : 'Without tags.'
              }`}
              className='col'
              isWarning={!allTagsMaintain || newTagsLength !== oldTagsLength}
              multiple
            />
            <VideoCompare
              newVideo={modelVersion.info.video}
              oldVideo={model.video}
            />
          </div>
        </Card.Body>
      </Card>
      {(modelVersion.info.featuredPhoto || modelVersion.info.photos) && (
        <Card>
          <Card.Header title='Photos' />
          <Card.Body>
            <div className='row'>
              {modelVersion.info.featuredPhoto && (
                <GalleryCompare
                  label='Featured photo'
                  className='col-2'
                  newItems={[modelVersion.info.featuredPhoto]}
                  oldItems={[model.featuredPhoto]}
                />
              )}
              {modelVersion.info.photos && (
                <GalleryCompare
                  label='Photos'
                  className='col ml-3'
                  newItems={modelVersion.info.photos}
                  oldItems={model.photos}
                />
              )}
            </div>
          </Card.Body>
        </Card>
      )}
      {modelVersion.info.finalFile && (
        <Card>
          <Card.Header title='Final files' />
          <Card.Body>
            <FilesCompare
              modelId={model.id}
              label='Files'
              newItems={modelVersion.info.finalFile}
              oldItems={model.finalFile}
            />
          </Card.Body>
        </Card>
      )}
      {modelVersion.info.amount != null && (
        <Card>
          <Card.Header title='Values' />
          <Card.Body>
            <div className='row'>
              <FormField
                label={
                  model.skipRaisingFunds
                    ? 'Value of each sale in reais'
                    : 'Total amount in reais'
                }
                value={formatCurrency(modelVersion.info.amount)}
                disabled
                helperText={`Original: ${
                  model.amount
                    ? formatCurrency(model.amount)
                    : 'Without amount.'
                }`}
                className='col'
                isWarning={modelVersion.info.amount !== model.amount}
              />
              {!model.skipRaisingFunds && (
                <>
                  <FormField
                    label='Number of quotas'
                    value={modelVersion.info.totalQuotas}
                    disabled
                    helperText={`Original: ${model.totalQuotas}`}
                    className='col'
                    isWarning={
                      modelVersion.info.totalQuotas !== model.totalQuotas
                    }
                  />
                  <FormField
                    label='Value without fees'
                    value={
                      quotaPrice ? formatCurrency(quotaPrice.withoutFee) : 0
                    }
                    disabled
                    helperText={`Original: ${
                      quotaPriceOld
                        ? formatCurrency(quotaPriceOld.withoutFee)
                        : 0
                    }`}
                    className='col'
                    isWarning={
                      modelVersion.info.totalQuotas !== model.totalQuotas
                    }
                  />
                </>
              )}
              <FormField
                label='Price on site'
                value={
                  quotaPrice ? formatCurrency(quotaPrice[quotaPriceKey]) : 0
                }
                disabled
                helperText={`Original: ${
                  quotaPriceOld
                    ? formatCurrency(quotaPriceOld[quotaPriceKey])
                    : 0
                }`}
                className='col'
                isWarning={quotaPrice?.withoutFee !== quotaPriceOld?.withoutFee}
              />
            </div>
          </Card.Body>
        </Card>
      )}
      <div className='px-3 pb-3'>
        <Button
          color='primary'
          className='mr-2'
          onClick={() => openModal(ModelVersionAnswers.ACCEPT)}
        >
          Accept Changes
        </Button>
        <Button
          color='danger'
          onClick={() => openModal(ModelVersionAnswers.REFUSE)}
        >
          Refuse Changes
        </Button>
      </div>
      <ModalConfirm
        show={showModal}
        onClose={closeModal}
        onConfirm={onConfirmModal}
        loadingConfirm={loading}
        answer={answer}
      />
    </>
  )
}

export default VersionForm
