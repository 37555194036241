import React from 'react'
import Modal from '../../../../Components/Modal'
import { Transaction } from '../../../../Interfaces/Transaction'
import formatCurrency from '../../../../Utils/FormatCurrency'

interface Props {
  transaction?: Transaction
  onClose: () => void
}

const TransactionDetail: React.FC<Props> = ({ transaction, onClose }) => {
  return (
    <Modal show={!!transaction} onClose={onClose} title='Transaction detail'>
      {transaction && (
        <table className='table'>
          <tbody>
            <tr>
              <th>Gross Value:</th>
              <td className='text-right'>
                {formatCurrency(transaction.value)}
              </td>
            </tr>
            <tr>
              <th>Fee:</th>
              <td className='text-right'>
                {formatCurrency(transaction.gatewayFee || 0)}
              </td>
            </tr>
            <tr>
              <th className='border-dark'>Net Value:</th>
              <td className='text-right border-dark'>
                {formatCurrency(transaction.netValue)}
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </Modal>
  )
}

export default TransactionDetail
