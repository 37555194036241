import React from 'react'
import { useLocation } from 'react-router-dom'
import Footer from './components/Footer'
import Header from './components/Header'
import Menu from './components/Menu'

interface Props {
  children: React.ReactNode
}

const Layout: React.FunctionComponent<Props> = ({ children }) => {
  const location = useLocation()
  React.useEffect(() => {
    const fixLayoutHeights = () => $('body').Layout()
    fixLayoutHeights()
  }, [location])
  return (
    <>
      <Header />
      <Menu />
      <div className='content-wrapper'>{children}</div>
      <Footer />
    </>
  )
}

export default Layout
