import React from 'react'
import { PaginationInfo } from '../../Interfaces/PaginationInfo'
import Pagination from '../Pagination'
import Select from '../Select'

interface Props {
  limit: number
  skip: number
  total: number
  totalShowing: number
  onChangeLimit?: React.ChangeEventHandler<HTMLSelectElement>
  onChangePage: (pagination: PaginationInfo) => void
}

const PaginationFooter: React.FC<Props> = ({
  limit,
  skip,
  total,
  totalShowing,
  onChangeLimit,
  onChangePage,
}) => {
  return (
    <div className='d-flex justify-content-between'>
      <div className='d-flex align-items-center'>
        <p className='mb-0 mr-2'>View:</p>
        <Select value={limit} onChange={onChangeLimit}>
          <Select.Option>10</Select.Option>
          <Select.Option>20</Select.Option>
          <Select.Option>50</Select.Option>
          <Select.Option>100</Select.Option>
          <Select.Option>500</Select.Option>
        </Select>
      </div>
      <div className=''>
        <Pagination
          className='m-0'
          pagination={{
            skip,
            limit,
          }}
          total={total}
          onChangePage={onChangePage}
        />
      </div>
      <div className='d-flex align-items-center'>
        <p className='m-0'>
          {totalShowing === 0
            ? 'Showing 0 items.'
            : `Showing ${skip + 1} to ${skip + totalShowing} of ${total}.`}
        </p>
      </div>
    </div>
  )
}

export default PaginationFooter
