import React from 'react'

interface Props {
  color?: 'primary' | 'secondary' | 'success' | 'info' | 'danger' | 'warning'
  isDismissible?: boolean
  onDismiss?: () => void
  title?: string
  titleIcon?: React.ReactNode
}

const Alert: React.FC<Props> = ({
  children,
  color = 'danger',
  isDismissible = false,
  onDismiss,
  title,
  titleIcon,
}) => {
  return (
    <div
      className={[
        'alert',
        `alert-${color}`,
        isDismissible ? 'alert-dismissible' : undefined,
      ].join(' ')}
    >
      {isDismissible && (
        <button type='button' className='close' onClick={onDismiss}>
          &times;
        </button>
      )}
      {(titleIcon || title) && (
        <h5>
          {titleIcon} {title}
        </h5>
      )}
      {children}
    </div>
  )
}

export default Alert
