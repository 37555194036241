import { MutationTuple, gql, useMutation } from '@apollo/client'

const COMPLETE_WITHDRAW = gql`
  mutation completeWithdraw($transactionId: ID!) {
    completeWithdraw(transactionId: $transactionId)
  }
`

interface WithdrawVars {
  transactionId: string
}

const useWithdrawComplete = (): MutationTuple<boolean, WithdrawVars> => {
  return useMutation(COMPLETE_WITHDRAW)
}

export default useWithdrawComplete
