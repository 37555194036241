import React from 'react'

const LayoutPublic: React.FC = ({ children }) => (
  <div className='login-page'>
    <div className='login-box'>
      <div className='login-logo'>
        <img
          src='dist/img/co-3d-logo.svg'
          alt='CO3D Logo'
          className='img-fluid'
          width={100}
          height={90}
        />
      </div>
      {children}
    </div>
  </div>
)

export default LayoutPublic
