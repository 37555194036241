import React from 'react'

interface Props {
  value?: string | number | string[]
}

export type OptionComponent = React.FC<Props>

const Option: OptionComponent = ({ children, value }) => (
  <option value={value}>{children}</option>
)

export default Option
