import { Category } from './Category'
import { ModelFile } from './ModelFile'
import { ModelsVersionsSearchable } from './ModelVersion'
import { Photo } from './Photo'
import { PublicUser } from './PublicUser'
import { SearchableResult } from './SearchableResult'
import { VideoInfo } from './VideoInfo'

export enum ModelStatus {
  'REQUESTED' = 'REQUESTED',
  'RAISING_FUNDS' = 'RAISING_FUNDS',
  'MODELING' = 'MODELING',
  'FINISHED' = 'FINISHED',
  'CANCELED' = 'CANCELED',
}

export interface Model {
  id: string
  name: string
  slug: string
  category: Category
  scale: string
  sensitiveContent: boolean
  featuredPhoto: Photo
  photos: Photo[]
  modeler?: PublicUser
  status: ModelStatus
  public: boolean
  createdAt: string
  description: string
  modelsVersionsSearchable: ModelsVersionsSearchable
  tags?: string[]
  skipRaisingFunds: boolean
  amount?: number
  totalQuotas?: number
  finalFile?: ModelFile[]
  video?: VideoInfo
}

export type ModelsSearchable = SearchableResult<Model>
