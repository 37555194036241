import React from 'react'
import { Link } from 'react-router-dom'

interface Props {
  title: string
  subtitle: string
  linkTo?: string
  linkLabel?: string
  bgColor?: 'primary' | 'secondary' | 'success' | 'info' | 'danger' | 'warning'
  titleIcon?: React.ReactNode
}

const SmallBox: React.FC<Props> = ({
  title,
  subtitle,
  linkTo,
  linkLabel = 'More info',
  bgColor = 'primary',
  titleIcon,
}) => {
  return (
    <div className={`small-box bg-${bgColor}`}>
      <div className='inner'>
        <h3>{title}</h3>
        <p>{subtitle}</p>
      </div>
      {titleIcon && <div className='icon'>{titleIcon}</div>}
      {linkTo && (
        <Link to={linkTo} className='small-box-footer'>
          {linkLabel} <i className='fas fa-arrow-circle-right' />
        </Link>
      )}
    </div>
  )
}

export default SmallBox
