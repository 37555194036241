import React from 'react'
import Body, { BodyComponent } from './components/Body'
import Footer, { FooterComponent } from './components/Footer'
import Header, { HeaderComponent } from './components/Header'

interface Props {
  className?: string
  collapsed?: boolean
}

type CardComponent = React.FC<Props> & {
  Body: BodyComponent
  Header: HeaderComponent
  Footer: FooterComponent
}

const Card: CardComponent = ({
  children,
  className = '',
  collapsed = false,
}) => (
  <div className={`card ${className} ${collapsed ? 'collapsed-card' : ''}`}>
    {children}
  </div>
)
Card.Body = Body
Card.Header = Header
Card.Footer = Footer

export default Card
