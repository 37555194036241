import React from 'react'
import useFinancialResults from '../../../../Services/FinancialResults'
import Loading from '../../../../Components/Loading'
import SmallBox from '../../../../Components/SmallBox'
import formatCurrency from '../../../../Utils/FormatCurrency'
import Alert from '../../../../Components/Alert'

const BalancesHome: React.FC = () => {
  const { loading, data, error } = useFinancialResults()
  return (
    <div className='container-fluid'>
      {loading ? (
        <Loading />
      ) : (
        <>
          {error ? (
            <Alert>An error occurred while querying the data.</Alert>
          ) : (
            <div className='row'>
              <div className='col-lg-4 col-12'>
                <SmallBox
                  title={formatCurrency(data?.balance || 0)}
                  subtitle='Balance'
                  linkTo='/transactions'
                  bgColor='info'
                  titleIcon={<i className='fas fa-wallet' />}
                />
              </div>
              <div className='col-lg-4 col-12'>
                <SmallBox
                  title={formatCurrency(data?.committedBalance || 0)}
                  subtitle='Committed Balance'
                  linkTo='/transactions'
                  bgColor='warning'
                  titleIcon={<i className='fas fa-ban' />}
                />
              </div>
              <div className='col-lg-4 col-12'>
                <SmallBox
                  title={formatCurrency(data?.availableBalance || 0)}
                  subtitle='Balance Available'
                  linkTo='/transactions'
                  bgColor='success'
                  titleIcon={<i className='fas fa-hand-holding-usd' />}
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default BalancesHome
