import React from 'react'

interface Props {
  isMenuEnd?: boolean
}

export type MenuComponent = React.FC<Props>

const Menu: MenuComponent = ({ children, isMenuEnd = false }) => (
  <div
    className={`dropdown-menu ${isMenuEnd ? 'dropdown-menu-right' : ''}`}
    role='menu'
  >
    {children}
  </div>
)

export default Menu
