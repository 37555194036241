import React, { createContext, useContext, useEffect, useState } from 'react'
import { Session } from '../Interfaces/Session'
import { User } from '../Interfaces/User'
import { setAccessToken } from '../Services/AccessToken'
import clearSession from '../Services/ClearSession'
import createSession from '../Services/CreateSesssion'
import refreshSession from '../Services/RefreshSession'

interface AuthContextProps {
  user?: User
  loadingUser: boolean
  signIn: (email: string, password: string) => Promise<void>
  signOut: () => Promise<void>
}

const AuthContext = createContext({} as AuthContextProps)

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User>()
  const [loadingUser, setLoadingUser] = useState(true)

  const setUserAndToken = (session: Session): void => {
    const userInfo = {
      id: session.user.id,
      name: session.user.name,
    }
    setUser(userInfo)

    const now = new Date()
    setAccessToken({
      token: session.accessToken,
      expiresIn: new Date(
        now.getTime() + parseInt(session.expiresIn, 10) * 1000
      ),
    })
  }

  useEffect(() => {
    const loadUser = async () => {
      try {
        const result = await refreshSession()
        if (result.status < 200 || result.status > 299) {
          setUser(undefined)
          setAccessToken(null)
        } else {
          setUserAndToken(await result.json())
        }
      } catch (err) {
        setUser(undefined)
        setAccessToken(null)
      }
      setLoadingUser(false)
    }
    loadUser()
  }, [])

  const signIn = async (email: string, password: string): Promise<void> => {
    const { data: session } = await createSession(email, password)
    if (session) {
      setUserAndToken(session.login)
    }
  }

  useEffect(() => {
    const syncLogout = (event: StorageEvent) => {
      if (event.key === 'logout' && !document.hasFocus()) {
        setUser(undefined)
        setAccessToken(null)
        document.location.reload()
      }
    }
    window.addEventListener('storage', syncLogout)
    return () => {
      window.removeEventListener('storage', syncLogout)
      window.localStorage.removeItem('logout')
    }
  }, [])

  const signOut = async () => {
    await clearSession()
    setUser(undefined)
    setAccessToken(null)
    window.localStorage.setItem('logout', Date.now().toString())
  }

  return (
    <AuthContext.Provider value={{ user, loadingUser, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = (): AuthContextProps => {
  return useContext(AuthContext)
}
