import { gql, QueryResult, useQuery } from '@apollo/client'

const GET_DOWNLOAD_URL = gql`
  query getModelDownloadUrl(
    $modelId: ID!
    $fileId: ID!
    $fromVersion: Boolean
  ) {
    getModelDownloadUrl(
      modelId: $modelId
      fileId: $fileId
      fromVersion: $fromVersion
    )
  }
`

interface GetModelDownloadUrlData {
  getModelDownloadUrl: string
}

export interface GetModelDownloadUrlVars {
  modelId: string
  fileId: string
  fromVersion?: boolean
}

const useGetModelDownloadUrl = (
  variables?: GetModelDownloadUrlVars,
  skip?: boolean
): QueryResult<GetModelDownloadUrlData> => {
  return useQuery<GetModelDownloadUrlData, GetModelDownloadUrlVars>(
    GET_DOWNLOAD_URL,
    {
      variables,
      skip,
    }
  )
}

export default useGetModelDownloadUrl
