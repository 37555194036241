import React from 'react'
import Button from '../Button'

const ErrorDisplay: React.FC = () => (
  <section className='content d-flex vh-100 align-items-center'>
    <div className='error-page'>
      <h2 className='headline text-danger'>500</h2>
      <div className='error-content p-3 p-md-0 text-center text-md-left'>
        <h3>
          <i className='fas fa-exclamation-triangle text-danger' /> Oops!
          Something went wrong.
        </h3>
        <p>
          We will work on fixing that right away. Meanwhile, you may reload the
          page.
        </p>
        <Button color='primary' onClick={() => window.location.reload()}>
          Reload page
        </Button>
      </div>
    </div>
  </section>
)

export default ErrorDisplay
